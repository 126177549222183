.trainer-overzicht-section {

    & .trainer-overzicht {
        /* padding: 0 5%; */

        & .top {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;

            & .title {
                
                & h2 {
                    font-size: 1.9em;
                }
            }

            & .select-trainers {

                & .club-select {
                    margin-bottom: 0;
                }
            }
        }

        & .trainers {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            & .trainer {
                display: none;
                padding: 0 10px;
                width: calc(100% / 2);
                margin-bottom: 30px;

                &.active {
                    display: block;
                }

                & a {
                    color: inherit;
                    text-decoration: none;
                }

                & .trainer-wrap:hover .image::after {
                    height: 100%;
                }

                & .image {
                    position: relative;
                    padding-top: 22px;
                    border-radius: 3px;
                    overflow: hidden;

                    & img {
                        position: relative;
                        z-index: 3;
                        display: block;
                        width: 100%;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 2;
                        width: 100%;
                        height: 80%;
                        bottom: 0;
                        left: 0;
                        background-color: var(--color-creme);
                        transition: .3s;
                    }
                }

                & .text {
                    text-align: center;

                    & h3 {
                        font-size: 1.5em;
                        margin: 30px 0 12px;
                    }

                    & .functie {
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .trainer-overzicht-section {

        & .trainer-overzicht {
            padding: 0 5%;
        }
    }
}

@media (--viewport-md-min) {
    .trainer-overzicht-section {

        & .trainer-overzicht {
            padding: 0 5%;
    
            & .top {
                flex-direction: row;
                align-items: center;
                margin-bottom: 40px;

                & .title {
                
                    & h2 {
                        font-size: 2.85em;
                    }
                }
            }

            & .trainers {
                margin: 0 -20px;

                & .trainer {
                    padding: 0 20px;
                    width: calc(100% / 3);
                    margin-bottom: 50px;
                }
            }
        }
    }
}