body {
    counter-reset: slides;
}

.image-slider-big-section {

    & .wrap { position: relative; }

    & .isb-carousel {

        & .carousel-cell {
            width: 100%;

            & .image {
                position: relative;

                & img {
                    display: block;
                    width: 100%;
                }

                & .text-overlay {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 0 0 24px 20px;
                    max-width: 100%;
                    color: #fff;

                    &.color-dark {
                        color: var(--color-blue);
                    }

                    & h2 {
                        font-size: 2em;
                        margin-bottom: 0;
                    }
                }
            }

            & .text {
                font-family: var(--heading-font-family);
                margin: 12px 0;
                font-size: 1em;
                padding-right: 62px;

                &::before {
                    counter-increment: slides;
                    content: counter(slides) "/";
                }

                & .slide-count {
                    margin-right: 8px;
                }
            }
        }
    }

    & .prev-next-buttons {
        position: absolute;
        bottom: 4px;
        padding: 12px 0;
        right: 0;
        display: flex;

        & .prev-next-button {
            width: 20px;
            cursor: pointer;

            &:hover svg path {
                fill: var(--color-pink);
            }

            & svg {
                display: block;
                width: 100%;
                height: auto;

                & path {
                    transition: .3s;
                }
            }

            &.prev-button {
                
            }

            &.next-button {
                margin-left: 6px;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .image-slider-big-section {
    
        & .isb-carousel {
    
            & .carousel-cell {
    
                & .image {
    
                    & .text-overlay {
    
                        & h2 {
                            font-size: 3em;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .image-slider-big-section {
    
        & .isb-carousel {
    
            & .carousel-cell {
    
                & .image {
    
                    & .text-overlay {
                        padding: 0 0 50px 60px;
    
                        & h2 {
                            font-size: 4em;
                        }
                    }
                }
    
                & .text {
                    margin: 12px 16px;
                    font-size: 1.2em;
                }
            }
        }

        & .prev-next-buttons {
            bottom: 6px;
            padding: 12px 16px;

            & .prev-next-button {
                width: 24px;
            }
        }
    }
}