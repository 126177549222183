.call-to-action-section {
    position: relative;
    background-color: var(--color-blue);
    overflow: hidden;
    margin: 0;
    padding-top: calc(var(--grid-section-margin) * 4);
    padding-bottom: calc(var(--grid-section-margin) * 4);

    & .call-to-action {
        display: flex;
        flex-direction: column;
    
        &.reverse {
            flex-direction: column;
        }
    
        & .text {
            align-self: center;
            width: 100%;
            padding: 0px;
            margin-bottom: 2em;
            color: #fff;
    
            & .small-top-heading {
                position: relative;
                display: block;
                font-size: 1.2em;
                font-family: var(--heading-font-family);
    
                & .sth-text {
                    position: relative;
                    z-index: 2;
                    display: inline-block;
                    padding-right: 1em;
                    background-color: var(--color-blue);
                }
    
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 140%;
                    height: 1px;
                    background-color: #fff;
                    top: 50%;
                    left: 0;
                }
            }
    
            & h2 {
                font-size: 1.9em;
                margin: 1.5em 0;
            }
    
            & .it-button {
                margin: 1.5em 0 0;
                
                & .button {
                    color: #fff;
                    border-color: #fff;

                    &:hover {
                        color: var(--color-blue);
                        background-color: #fff;
                    }

                    &.alt {
                        border-color: var(--color-pink);

                        &:hover {
                            color: #fff;
                            background-color: var(--color-pink);
                        }
                    }
                }
            }
        }
        
        & .image {
            width: 100%;
        }
    }
}

@media (--viewport-sm-min) {
    .call-to-action-section {
        padding-top: calc(var(--grid-section-margin) * 0);
        padding-bottom: calc(var(--grid-section-margin) * 7);

        &:before {
            content: '';
            width: 100%;
            height: 40px;
            background-color: #fff;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
        }

        & .call-to-action {
            position: relative;
            z-index: 2;
            flex-direction: row;
        
            &.reverse {
                flex-direction: row-reverse;

                & .text .small-top-heading:after {
                    width: 100%;
                }
            }
        
            & .text {
                width: 50%;
                padding: 110px 5% 0;
                margin-bottom: 0;

                & h2 {
                    margin: 1.2em 0;
                }

                & .it-button {
                    margin: 2em 0 0;
                }
            }
            
            & .image {
                width: 50%;

                & figure {
                    height: 100%;

                    & img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .call-to-action-section {
        padding-bottom: calc(var(--grid-section-margin) * 11);

        &:before {
            height: 60px;
        }

        & .call-to-action {
        
            & .text {
                padding: 160px 5% 0;

                & .small-top-heading {
                    font-size: 1.3em;
                }

                & h2 {
                    font-size: 2.25em;
                    margin: 1.6em 0;
                }

                & .it-button {
                    margin: 2.5em 0 0;
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .call-to-action-section {

        & .call-to-action {
        
            & .text {

                & .small-top-heading {
                    font-size: 1.4em;
                }

                & h2 {
                    font-size: 2.85em;
                    margin: 1.9em 0;
                }

                & .it-button {
                    margin: 3em 0 0;
                }
            }
        }
    }
}