.module-rich-text {

    & h1 {
        margin-top: 0px;
        margin-bottom: 22px;
    }

    & h2 {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    & h3 {
        margin-top: 0px;
        margin-bottom: 18px;
    }

    & p {
        margin-top: 0px;
        margin-bottom: 40px;
    }

    & img {
        max-width: 100%;
        height: auto;
    }
}