.module-card {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    background-color: var(--color-white);
    padding: 30px 40px 50px;
    border-radius: 5px;
    margin-bottom: 20px;
}

@media (--viewport-md-min) {
    .module-card {
        padding: 40px 50px 60px;
    }
}