form {

    .row {
        margin: 0 -12px;
    }

    .col-xs-12 {
        padding: 0 12px;
    }    
}

input {
    width: 100%;
    padding: 12px 2px;
    border: none;
    border-bottom: 1px solid var(--input-border-color);
    border-radius: 0;
    resize: vertical;
    margin-bottom: 12px;
    background-color: var(--input-background-color);
    font-family: var(--base-font-family);
    font-size: 16px;

    &::placeholder {
        color: #7b7b7b;
    }

    &[type=submit] {
        width: auto;
        border-radius: 3px;
        margin-top: 8px;
        padding: 12px 24px;
        line-height: 1.25em;
        border: 1px solid var(--color-pink);
        background-color: var(--color-pink);
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            border: 1px solid var(--color-blue);
            background-color: var(--color-blue);
            color: #fff;
            transition: all .2s ease-in-out;
        }
    }
}

label {
    color: var(--input-label-color);
    font-size: 1em;
    margin: 10px 0 6px;
    display: inline-block;
}

.custom-checkbox {
    position: relative;
    display: block;
    min-height: 1.5rem;
    /* margin: 1.5em 0; */

    &:first-child label {
        margin: 10px 0 3px;
    }

    &:last-child label {
        margin: 3px 0 20px;
    }

    & input[type=checkbox] {
        width: auto;
        box-sizing: border-box;
        padding: 0;
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked {

            & ~ label {

                &::before {
                    box-shadow: 0 0 0 0 transparent;
                    border-color: var(--input-border-color);
                    background-color: var(--input-background-color);
                }

                &::after {
                    display: block;
                }
            }
        } 
    }

    & label {
        cursor: pointer;
        position: relative;
        vertical-align: top;
        padding-left: 1.5rem;
        font-weight: 700;

        &::before {
            border-radius: 0;

            transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            pointer-events: none;
            background-color: var(--input-background-color);
            border: 2px solid var(--input-border-color);

            position: absolute;
            top: 2px;
            left: 0;
            display: block;
            width: 18px;
            height: 18px;
            content: "";
        }

        &::after {
            content: "";
            position: absolute;
            display: none;

            top: 5px;
            left: 6.5px;
            width: 5px;
            height: 10px;
            border: solid var(--input-check-color);
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.custom-radio {
    position: relative;
    display: block;
    min-height: 1.5rem;
    font-weight: 700;

    &:first-child label {
        margin: 10px 0 3px;
    }

    &:last-child label {
        margin: 3px 0 20px;
    }

    & input[type=radio] {
        width: auto;
        box-sizing: border-box;
        padding: 0;
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked {

            & ~ label {

                &::before {
                    box-shadow: 0 0 0 0 transparent;
                    border-color: var(--input-border-color);
                    background-color: var(--input-background-color);
                }

                &::after {
                    display: block;
                }
            }
        } 
    }

    & label {
        cursor: pointer;
        position: relative;
        vertical-align: top;
        margin: 3px 0;
        padding-left: 1.5rem;

        &::before {
            border-radius: 100%;
            transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            pointer-events: none;
            background-color: var(--input-background-color);
            border: 2px solid var(--input-border-color);
            position: absolute;
            top: .2rem;
            left: 0;
            display: block;
            width: 18px;
            height: 18px;
            content: "";
        }

        &::after {
            content: "";
            position: absolute;
            display: none;
            top: calc(.2rem + 6px);
            left: 6px;
            width: 6px;
            height: 6px;
            background-color: var(--input-check-color);
            border-radius: 100%;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.custom-select {
    border: none;
    border-bottom: 1px solid var(--input-border-color);
    width: 100%;
    padding: 0;
    border-radius: 0;
    overflow: hidden;
    margin-bottom: 12px;
    background: var(--input-background-color) url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    font-size: 16px;

    & select {
        cursor: pointer;
        padding: 14px 2px;
        background-color: var(--input-background-color);
        width: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        font-family: var(--base-font-family);
        background-image: none;
        -webkit-appearance: none;
    }
}

select {
    color: inherit;
}

select:required {
    color: black;
}

option[value=""][disabled],
option[value="undefined"][disabled] {
    display: none;
}

option {
    color: black;
}

.custom-submit {
    overflow: auto;
}

textarea {
    width: 100%;
    padding: 12px 2px;
    border: none;
    border-bottom: 1px solid var(--input-border-color);
    border-radius: 0;
    resize: vertical;
    margin-bottom: 12px;
    background-color: var(--input-background-color);
    font-family: var(--base-font-family);
    font-size: 16px;

    &::placeholder {
        color: #7b7b7b;
    }
}

fieldset {
    padding: 0;
    border: none;
}

.club-select {
    display: inline-block;
    border: none;
    border-bottom: 1px solid var(--input-border-color);
    /* width: auto; */
    min-width: 296px;
    padding: 0;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 12px;
    background: var(--color-blue) url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJtYXAtbWFya2VyLWFsdCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIiBjbGFzcz0iIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTcyLjI2OCA1MDEuNjdDMjYuOTcgMjkxLjAzMSAwIDI2OS40MTMgMCAxOTIgMCA4NS45NjEgODUuOTYxIDAgMTkyIDBzMTkyIDg1Ljk2MSAxOTIgMTkyYzAgNzcuNDEzLTI2Ljk3IDk5LjAzMS0xNzIuMjY4IDMwOS42Ny05LjUzNSAxMy43NzQtMjkuOTMgMTMuNzczLTM5LjQ2NCAwek0xOTIgMjcyYzQ0LjE4MyAwIDgwLTM1LjgxNyA4MC04MHMtMzUuODE3LTgwLTgwLTgwLTgwIDM1LjgxNy04MCA4MCAzNS44MTcgODAgODAgODB6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+');
    background-repeat: no-repeat, repeat;
    background-position: right 26px top 50%, 0 0;
    background-size: 15px auto, 100%;
    transition: .3s;

    &:hover {
        background-color: var(--color-pink);
        border-bottom: 1px solid var(--color-pink);
    }

    & select {
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        padding: 14px 36px 14px 24px;
        background-color: var(--input-background-color);
        width: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        font-family: var(--base-font-family);
        background-image: none;
        -webkit-appearance: none;

        & option {
            color: #000;
            font-weight: 400;
            text-transform: none;
        }
    }
}
