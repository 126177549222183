.form-container-section {
    background-color: var(--color-creme);
    margin: 0;
    padding-top: calc(var(--grid-section-margin) * 4);

    & .form-container {

        & h2 {
            font-size: 1.9em;
            margin: 0 0 1.5em;
        }

        & .form {
            padding-bottom: 50px;

            & .club-select {
                margin: 0 0 26px;
            }

            & input[type=submit] {
                border: 1px solid var(--color-blue);
                background-color: transparent;
                color: var(--color-blue);
        
                &:hover {
                    border: 1px solid var(--color-pink);
                    background-color: var(--color-pink);
                    color: #fff;
                }
            }
        }

        & .image {
            display: none;
        }
    }
}

@media (--viewport-sm-min) {
    .form-container-section {
        padding-top: calc(var(--grid-section-margin) * 7);

        & .form-container {
            display: flex;
    
            & .form {
                width: 55%;
                padding: 0 5% 50px;
            }

            & .image {
                display: block;
                place-self: flex-end;
                width: 45%;
                padding: 0 5% 0 0;
            }
        }
    }
}

@media (--viewport-md-min) {
    .form-container-section {
        padding-top: calc(var(--grid-section-margin) * 11);

        & .form-container {
            display: flex;
    
            & .form {
                width: 60%;
                padding: 0 5% 90px;

                & h2 {
                    font-size: 2.85em;
                }
            }

            & .image {
                place-self: flex-end;
                width: 40%;
                padding: 0 5%;
            }
        }
    }
}