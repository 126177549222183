.link-through-section {

    .wrap {

        p {
            font-size: 16px;
            margin-bottom: 22px;
        }

        .location-select {
            position: relative;
            display: inline-block;
            color: #fff;
    
            .select-opt {
                display: inline-block;
                background-color: var(--color-blue);
                font-weight: 600;
                text-transform: uppercase;
                padding: 12px 22px;
                line-height: 1.25;
                border-radius: 3px;
                cursor: pointer;
                transition: .3s;
    
                &.active {
                    border-top-right-radius: 0px;
                    border-top-left-radius: 0px;
                }
    
                &:hover {
                    background-color: var(--color-pink);
                }
    
                .select-opt-wrap {
                    display: flex;
                    align-items: center;
    
                    .select-opt-text {
                        margin-right: 1em;
                    }
    
                    svg {
                        width: 16px;
                    }
                }
            }
    
            .select-list {
                position: absolute;
                bottom: 45px;
                width: 100%;
                transform: scaleY(0);
                transform-origin: bottom;
                transition: .2s;
    
                &.active {
                    transform: scaleY(1);
    
                    li {
                        opacity: 1;
                    }
                }
    
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 8px 0 6px;
                    background-color: var(--color-blue);
                    border-top-right-radius: 3px;
                    border-top-left-radius: 3px;
                    font-weight: 600;
    
                    li {
                        /* margin: 8px 0 0 0; */
                        opacity: 0;
                        transition: transform .3s .2s, background-color .3s;
    
                        &:hover {
                            background-color: var(--color-pink);
                        }
    
                        a {
                            color: inherit;
                            text-decoration: none;
                            transition: .2s;
                            display: block;
                            padding: 4px 22px;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .link-through-section {

        .wrap {
            padding: 0 5%;

            p {
                font-size: 17px;
                margin-bottom: 26px;
            }
    
        }
    }
}