.nieuws-tile {

    & .image {
        background-color: #000;
    }

    & a {
        color: inherit;
        text-decoration: none;
    }

    & .content {

        & .content-wrap {
            position: relative;
            padding-right: 20px;

            & .tag {
                position: absolute;
                background-color: var(--color-blue);
                color: #fff;
                font-weight: 600;
                text-transform: uppercase;
                border-radius: 3px;
                top: 0;
                right: 0;
                font-size: .8em;
                padding: 10px 14px;
                line-height: 1.2;
                transform: translateY(-50%);
            }

            & .date {
                font-weight: 600;
                display: block;
                padding-top: 26px;
            }

            & .title {

                & h2 {
                    font-size: 1.7em;
                }
            }

            & .text {

                & .nieuws-p {
                    /* font-size: .9em; */
                }

                & .nieuws-button {
                    margin-top: 22px;

                    & .button {
                        font-size: 0.9em;
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .nieuws-tile {

        & .content {

            & .content-wrap {
                padding-right: 40px;

                & .tag {
                    padding: 12px 16px;
                    font-size: 1em;
                }

                & .date {
                    padding-top: 34px;
                }

                & .title {

                    & h2 {
                        font-size: 2.05em;
                    }
                }

                & .text {

                    & .nieuws-p {
                        /* font-size: 1em; */
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .nieuws-tile {
    
        & .content-wrap {
            padding-right: 80px;

            & .tag {
                padding: 12px 16px;
                font-size: 1em;
            }

            & .date {
                padding-top: 34px;
            }

            & .title {

                & h2 {
                    font-size: 2.25em;
                }
            }

            & .text {

                & .nieuws-p {
                    /* font-size: 1em; */
                }
            }
        }
    }
}