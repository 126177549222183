.footer {
    margin: 0;
    padding: 40px 0;
    background-color: var(--color-blue);
    color: #fff;

    & .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & .footer-list {
            /* max-width: 50%; */
        }
    }

    & .footer-link-list {

        & a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        & h3 {
            font-size: 1.4em;
        }

        & ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 0.9em;
            line-height: 2;

            & li {

            }
        }

        & .footer-buttons {
            margin: 2em 0;
            
            & .button {
                background-color: transparent;
                color: #fff;
                border: 1px solid #fff;

                &:hover {
                    background-color: #fff;
                    color: var(--color-blue);
                    border: 1px solid #fff;
                }

                &.alt {
                    background-color: transparent;
                    color: #fff;
                    border: 1px solid var(--color-pink);
            
                    &:hover {
                        background-color: var(--color-pink);
                        color: var(--color-white);
                        border: 1px solid var(--color-pink);
                    }
                }
            }
        }
    }
}

.sub-footer {
    background-color: var(--color-creme);
    margin: 0;
    padding: 30px 0;

    & .wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        & .footer-logo {

            & img {
                display: block;
                width: 180px;
            }
        }

        & .footer-socials {
            margin: 12px 0 0;

            & ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;

                & li {

                    & img {
                        width: 26px;
                        margin: 0 6px;
                    }
                }
            }
        }
    }
}

@media (--viewport-ms-min) {
    .footer {

        & .wrap {

            & .footer-list {
                width: 50%;
            }
        }
    }

    .sub-footer {
    
        & .wrap {
            flex-direction: row;
    
            & .footer-logo {
    
                & img {
                    width: 220px;
                }
            }
    
            & .footer-socials {
                margin: 0 0 12px;
    
                & ul {
    
                    & li {
    
                        & img {
                            width: 30px;
                            margin: 0 12px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .footer {

        & .wrap {
            padding: 0 5%;
        }
    }

    .sub-footer {

        & .wrap {
            padding: 0 5%;
        }
    }
}

@media (--viewport-md-min) {
    .footer {
        padding: 60px 0;

        & .wrap {

            & .footer-list {
                width: 25%;
            }
        }
    }

    .sub-footer {
    
        & .wrap {
    
            & .footer-socials {
                margin: 12px 0 0;
            }
        }
    }
}