.header-fix-section {
    margin: 0;
    height: 100px;

    &:after {
        content: '';
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: var(--color-blue);
        transition: .3s;
    }
}

.navScroll {
    & .header-fix-section:after {
        height: 78px;
    }
}

@media (--viewport-md-min) {
    .header-fix-section {
        margin: 0;
        height: 128px;

        &:after {
            height: 128px;
        }
    }

    .navScroll .header-fix-section:after {
        height: 98px;
    }
}