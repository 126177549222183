.header-menu-overlay {
    position: fixed;
    z-index: 208;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #021a2b00;
    color: #fff;
    transform: translateY(-100%);
    overflow: auto;

    & .header-align-center {
        display: flex;
        min-height: 100%;
        align-items: center;
    }

    &.menu-open {
        animation: menu-animation-wrapper both 1200ms;

        & .bckground-animation {
            /* animation: menu-animation-background both 1200ms; */
        }

        & .wrap .animation-wrap {
            animation: menu-animation-links both 1200ms;
        }
    }

    &.menu-closed {
        animation: menu-closed-animation-wrapper both 1000ms reverse;

        & .bckground-animation {
            /* animation: menu-closed-animation-background both 1000ms reverse; */
        }

        & .wrap {
                animation: menu-closed-animation-links-fix both 1000ms reverse;

            & .animation-wrap {
                animation: menu-closed-animation-links both 1000ms reverse;
            }
        }
    }

    & a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
    }

    & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        & li {
            display: block;
            position: relative;

            & a {

                &:before {
                    content: '';
                    width: 6px;
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    opacity: 0;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: var(--color-pink);
                    pointer-events: none;
                }

                &:after {
                    content: '';
                    width: 6px;
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    opacity: 0;
                    right: 0;
                    transform: translateY(-50%);
                    background-color: var(--color-pink);
                    pointer-events: none;
                }

                &:hover {

                    &:before {
                        opacity: 1;
                    }

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    & .wrap {
        position: relative;
        z-index: 2;
        padding: 80px 0 130px;
        text-align: center;

        & .animation-wrap {

            & .locaties {

                & h2 {
                    font-size: 1.8em;
                    margin: .6em 0;
                }

                & ul {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: .85em;
                    line-height: 2;

                    & li {
                        padding: 0;
                    }
                }
            }

            & .links {
                margin: 2em 0;

                & ul {
                    font-size: 1.8em;
                    line-height: 1.3;
                    font-family: var(--heading-font-family);

                    & li {
                        padding: 0;
                    }
                }
            }

            & .menu-buttons {

                & .button {
                    display: inline-block;
                    background-color: transparent;
                    color: #fff;
                    border: 1px solid #fff;

                    &:hover {
                        background-color: #fff;
                        color: var(--color-blue);
                        border: 1px solid #fff;
                    }

                    &.alt {
                        background-color: transparent;
                        color: #fff;
                        border: 1px solid var(--color-pink);

                        &:hover {
                            background-color: var(--color-pink);
                            color: var(--color-white);
                            border: 1px solid var(--color-pink);
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .header-menu-overlay {

        & .wrap {
            padding: 120px 0;

            & .animation-wrap {

                & .locaties {

                    & h2 {
                        font-size: 2.15em;
                        margin: .6em 0;
                    }

                    & ul {
                        font-size: .9em;
                        line-height: 1.7;
                    }
                }

                & .links {

                    & ul {
                        font-size: 2.15em;
                    }
                }
            }
        }
    }
}

/* Animations */
/* Menu open */
@keyframes menu-animation-wrapper {
    0%   {
        transform: translateY(0);
    }
    70%   {
        background-color: #021a2b;
    }
    100% {
        transform: translateY(0);
        background-color: #021a2b;
    }
}

@keyframes menu-animation-links {
    0%   {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

/* Menu closed */
@keyframes menu-closed-animation-wrapper {
    0%   {
        transform: translateY(100%);
    }
    1% {
        transform: translateY(0);
    }
    50% {
        background-color: #021a2b;
    }
    100% {
        transform: translateY(0);
        background-color: #021a2b;
    }
}

@keyframes menu-closed-animation-links-fix {
    0%   {
        overflow: hidden;
    }
    100% {
        overflow: hidden;
    }
}

@keyframes menu-closed-animation-links {
    0%   {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}