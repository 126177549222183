.news-page-title,
.compact-page-title {

    .container {

        .content {
            display: flex;
            flex-direction: column;

            h1 {
                margin-bottom: 20px;
            }

            .location-select {
                position: relative;
                display: inline-block;
                color: #fff;

                .select-opt {
                    display: inline-block;
                    background-color: var(--color-blue);
                    font-weight: 600;
                    text-transform: uppercase;
                    padding: 12px 22px;
                    line-height: 1.25;
                    border-radius: 3px;
                    cursor: pointer;
                    transition: .3s;

                    &.active {
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }

                    &:hover {
                        background-color: var(--color-pink);
                    }

                    .select-opt-wrap {
                        display: flex;
                        align-items: center;

                        .select-opt-text {
                            margin-right: 1em;
                        }

                        svg {
                            width: 16px;
                        }
                    }
                }

                .select-list {
                    position: absolute;
                    top: 45px;
                    width: 100%;
                    transform: scaleY(0);
                    transform-origin: top;
                    transition: .2s;

                    &.active {
                        transform: scaleY(1);

                        li {
                            opacity: 1;
                        }
                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 6px 0 8px;
                        background-color: var(--color-blue);
                        border-bottom-right-radius: 3px;
                        border-bottom-left-radius: 3px;
                        font-weight: 600;

                        li {
                            /* margin: 8px 0 0 0; */
                            opacity: 0;
                            transition: transform .3s .2s, background-color .3s;

                            &:hover {
                                background-color: var(--color-pink);
                            }

                            a {
                                color: inherit;
                                text-decoration: none;
                                transition: .2s;
                                display: block;
                                padding: 4px 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.nieuws-overzicht-section {

    .wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .nieuws-tile {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}

@media (--viewport-sm-min) {
    .news-page-title,
    .compact-page-title {

        .container {

            .content {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .nieuws-overzicht-section {

        .wrap {
            padding: 0 5%;

            .nieuws-tile {
                width: 48%;
                margin-bottom: 50px;
            }
        }
    }
}