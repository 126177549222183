.top-bar-notice {
    position: fixed;
    z-index: 99999;
    top: 0;
    width: 100%;
    left: 0;
    background-color: var(--color-pink);

    &.hide {
        display: none;
    }

    .top-bar-notice-wrap {
        text-align: center;
        padding: 6px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .top-bar-notice-message {

        }

        .top-bar-notice-read-more {
            margin-right: 8px;
            a {
                color: #fff;

                &:hover {
                    color: var(--color-blue);
                }
            }
        }

        .top-bar-notice-close {
            display: inline-block;
            margin-top: 2px;
            width: 20px;
            height: 20px;
            /* background-color: var(--color-blue); */
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10 fa-xs'%3E%3Cpath fill='#ffffff' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' class=''%3E%3C/path%3E%3C/svg%3E");
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10 fa-xs'%3E%3Cpath fill='#021A2B' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' class=''%3E%3C/path%3E%3C/svg%3E");
            }
        }
    }
}