.page-title {
    margin: 40px 0;

    &.small-text .container .content h1 {
        font-size: 30px;
    }

    & .container {

        & .content {

            & h1 {
                font-size: 2.5em;
                margin: 0;
            }

            & p {
                font-weight: 600;
                text-transform: uppercase;
                font-size: 1em;
            }

            .vds-icons {
                margin-top: 8px;
            }
        }
    }
}

@media (--viewport-ms-min) {
    .page-title {
        margin: 50px 0;

        & .container {

            & .content {

                & h1 {
                    font-size: 3.2em;
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .page-title {
        margin: 60px 0;

        & .container {

            & .content {
                padding: 0 5%;

                & h1 {
                    font-size: 3.4em;
                }

                & p {
                    font-size: 1.1em;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .page-title {
        margin: 80px 0;

        &.small-text .container .content h1 {
            font-size: 46px;
        }

        & .container {

            & .content {

                & h1 {
                    font-size: 4.6em;
                }

                & p {
                    font-size: 1.3em;
                }
            }
        }
    }
}