.image-text-alt-section {
    overflow: hidden;
    background-color: var(--color-creme);
    margin: 0;
    padding: 40px 0;

    & .image-text-alt {
        display: flex;
        flex-direction: column;
    
        &.reverse {
            flex-direction: column;
        }
    
        & .text {
            align-self: center;
            width: 100%;
            padding: 0px;
            margin-bottom: 2em;
    
            & .small-top-heading {
                position: relative;
                z-index: 3;
                display: block;
                font-size: 1.2em;
                font-family: var(--heading-font-family);
    
                & .sth-text {
                    position: relative;
                    z-index: 2;
                    display: inline-block;
                    padding-right: 1em;
                    background-color: var(--color-creme);
                }
    
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 140%;
                    height: 1px;
                    background-color: var(--color-blue);
                    top: 50%;
                    left: 0;
                }
            }
    
            & h2 {
                font-size: 1.9em;
                margin: 1.5em 0;
            }
    
            & .it-button {
                margin: 1.5em 0 0;
            }
        }
        
        & .image {
            width: 100%;
        }
    }
}

@media (--viewport-ms-max) {
    .image-text-alt-section .image-text.mobile-image-first {
        flex-direction: column-reverse;

        & .text {
            margin-bottom: 0;
        }

        & .image {
            margin-bottom: 2em;
        }
    }
}

@media (--viewport-sm-min) {
    .image-text-alt-section {
        padding-top: calc(var(--grid-section-margin) * 7);
        padding-bottom: calc(var(--grid-section-margin) * 7);

        & .image-text-alt {
            flex-direction: row;
        
            &.reverse {
                flex-direction: row-reverse;

                & .text .small-top-heading:after {
                    width: 100%;
                }
            }
        
            & .text {
                width: 50%;
                padding: 2em 5%;
                margin-bottom: 0;

                & .small-top-heading {
                    /* font-size: 1.4em; */
                }

                & h2 {
                    /* font-size: 2.85em; */
                    margin: 1.2em 0;
                }

                & .it-button {
                    margin: 2em 0 0;
                }
            }
            
            & .image {
                width: 50%;

                & figure {
                    position: relative;
                    height: calc(100% - 90px);
                    width: calc(100% - 90px);

                    & img {
                        position: relative;
                        z-index: 2;
                        height: 100%;
                        object-fit: cover;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        background-color: var(--color-blue);
                        width: 180px;
                        height: 180px;
                        bottom: -90px;
                        right: -90px;
                        border-bottom-right-radius: 45px;
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .image-text-alt-section {
        padding-top: calc(var(--grid-section-margin) * 11);
        padding-bottom: calc(var(--grid-section-margin) *11);

        & .image-text-alt {
            flex-direction: row;
        
            &.reverse {
                flex-direction: row-reverse;

                & .text .small-top-heading:after {
                    width: 100%;
                }
            }
        
            & .text {
                width: 50%;
                padding: 2em 5%;

                & .small-top-heading {
                    font-size: 1.3em;
                }

                & h2 {
                    font-size: 2.25em;
                    margin: 1.6em 0;
                }

                & .it-button {
                    margin: 2.5em 0 0;
                }
            }
            
            & .image {
                padding-right: 5%;

                & figure {
                    height: calc(100% - 130px);
                    width: calc(100% - 130px);

                    &:after {
                        width: 260px;
                        height: 260px;
                        bottom: -130px;
                        right: -130px;
                        border-bottom-right-radius: 50px;
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .image-text-alt-section {

        & .image-text-alt {
        
            & .text {

                & .small-top-heading {
                    font-size: 1.4em;
                }

                & h2 {
                    font-size: 2.85em;
                    margin: 1.9em 0;
                }

                & .it-button {
                    margin: 3em 0 0;
                }
            }
        }
    }
}