.review-slider-section {
    overflow: hidden;
    background-color: var(--color-creme);
    margin: 0;
    padding: 40px 0;

    & .header {
        margin-bottom: 2em;

        & h2 {
            position: relative;
            display: block;
            font-size: 1.2em;
            font-family: var(--heading-font-family);

            & .sth-text {
                position: relative;
                z-index: 2;
                display: inline-block;
                padding-right: 1em;
                background-color: var(--color-creme);
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 140%;
                height: 1px;
                background-color: var(--color-blue);
                top: 50%;
                left: 0;
            }
        }

    }

    & .review-button-group {
        /* padding-left: 5%; */
        margin-bottom: 24px;

        & .reviewButton {
            display: inline-block;
            width: 21px;
            height: 8px;
            background-color: var(--color-blue);
            border: none;
            margin-right: 6px;
            cursor: pointer;
            transition: .2s;

            &.active {
                background-color: var(--color-pink);
            }

            &:hover {
                background-color: var(--color-pink);
            }

            &:focus {
                border-radius: 0;
                outline: none;
            }
        }
    }

    & .review-carousel {
        width: calc(100vw - var(--half-gutter-width) );

        & .carousel-cell {
            width: 94%;
            padding-right: 22px;

            &.active {

                & .image img {
                    opacity: 1;
                }

                & .content {
                    opacity: 1;
                }
            }

            & .image {
                background-color: #000;

                & img {
                    opacity: .2;
                    transition: .3s;
                }
            }

            & .content {
                opacity: .1;
                transition: .3s;

                & .content-wrap {
                    position: relative;
                    padding-right: 20px;
    
                    & .title {

                        & h2 {
                            font-size: 1.7em;
                        }
                    }

                    & .text {

                        & .review-p {
                            /* font-size: .9em; */
                        }

                        & .review-rating {
                            margin-top: 24px;
                            color: var(--color-pink);
                        }

                        & .review-name {
                            font-weight: 600;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .review-slider-section {
        padding: 60px 0;
    
        & .header {
            padding-left: 5%;
        }
    
        & .review-button-group {
            padding-left: 5%;
            margin-bottom: 30px;
        }

        & .review-carousel {
            padding-left: 5%;
            width: calc(100vw - var(--half-gutter-width) );

            & .carousel-cell {
                width: 56%;
                padding-right: 26px;

                & .content {
    
                    & .content-wrap {
                        padding-right: 40px;
    
                        & .title {
    
                            & h2 {
                                font-size: 2.05em;
                            }
                        }

                        & .text {
    
                            & .review-p {
                                /* font-size: 1em; */
                            }

                            & .review-rating {
                                margin-top: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .review-slider-section {
        padding: 90px 0;
    
        & .header {
            margin-bottom: 2.5em;

            & h2 {
                font-size: 1.3em;

                &:after {
                    width: 100%;
                }
            }

        }

        & .review-carousel {
            width: 100vw;

            & .carousel-cell {
                width: 50%;
                padding-right: 36px;

                & .content {
    
                    & .content-wrap {
                        padding-right: 80px;
    
                        & .title {
    
                            & h2 {
                                font-size: 2.25em;
                            }
                        }

                        & .text {
    
                            & .review-p {
                                /* font-size: 1em; */
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .review-slider-section {
        padding: 90px 0 110px;
    
        & .header {

            & h2 {
                font-size: 1.4em;
            }

        }

        & .review-carousel {
            width: calc(100vw - ((100vw - var(--site-width) + var(--gutter-width)) / 2));

            & .carousel-cell {
                width: calc((var(--site-width) - var(--gutter-width)) / 2);
            }
        }
    }
}