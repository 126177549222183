.diensten-overzicht-section {
    overflow: hidden;

    & .wrap {

        & .header {
            margin-bottom: 2em;

            & h2 {
                position: relative;
                display: block;
                font-size: 1.2em;
                font-family: var(--heading-font-family);
    
                & .sth-text {
                    position: relative;
                    z-index: 2;
                    display: inline-block;
                    padding-right: 1em;
                    background-color: #fff;
                }
    
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 140%;
                    height: 1px;
                    background-color: var(--color-blue);
                    top: 50%;
                    left: 0;
                }
            }

        }

        & .diensten {
            display: flex;
            justify-content: left;
            flex-wrap: wrap;
            margin: 0 -6px;

            & .dienst-tile {
                width: 50%;
                padding: 0 6px;
                margin-bottom: 12px;

                & a {
                    position: relative;
                    display: block;
                    text-decoration: none;
                    color: inherit;
                }

                & img {
                    display: block;
                    width: 100%;
                }

                & .dienst-name {
                    padding: 12px 0;
                    color: var(--color-blue);
                    font-family: var(--heading-font-family);
                }
            }
        }

        & .diensten-button {
            text-align: center;
            margin-top: 1em;

            & .button {
                margin-right: 0;
            }
        }
    }
}

@media (--viewport-ms-max) {
    .diensten-overzicht-section .wrap .diensten.limit .dienst-tile:nth-child(n+5) {
        display: none;
    }
}

@media (--viewport-sm-min) {
    .diensten-overzicht-section {

        & .wrap {
            padding: 0 5%;
    
            & .diensten {
                margin: 0 -8px;
    
                & .dienst-tile {
                    width: 33%;
                    padding: 0 8px;
                    margin-bottom: 16px;

                    & .dienst-name {
                        font-size: 1.1em;
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-max) {
    .diensten-overzicht-section .wrap .diensten.limit .dienst-tile:nth-child(n+7) {
        display: none;
    }
}

@media (--viewport-md-min) {
    .diensten-overzicht-section {

        & .wrap {
    
            & .header {
                margin-bottom: 2.5em;
    
                & h2 {
                    font-size: 1.3em;
    
                    &:after {
                        width: 100%;
                    }
                }
    
            }
    
            & .diensten {
    
                & .dienst-tile {
                    width: 25%;

                    & .dienst-name {
                        font-size: 1.2em;
                    }
                }

                &.limit {

                    & .dienst-tile:nth-child(n+9) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .diensten-overzicht-section {

        & .wrap {
    
            & .header {
    
                & h2 {
                    font-size: 1.4em;
                }
    
            }
    
            & .diensten {
    
                & .dienst-tile {
                    width: 25%;

                    & .dienst-name {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: var(--color-blue);
                        color: #fff;
                        font-family: var(--heading-font-family);
                        font-size: 22px;
                        padding: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        opacity: 0;
                        transition: .3s;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &.limit {

                    & .dienst-tile:nth-child(n+9) {
                        display: none;
                    }
                }
            }
        }
    }
}