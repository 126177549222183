/**
 * Base
 */
:root {
    --site-background-color: var(--site-background-color, rgb(255, 255, 255));
    scroll-behavior: smooth;
}

/* Box sizing */
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

/* Body */
body {
    background-color: var(--site-background-color);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight);
    font-size: var(--base-font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    line-height: 1.6;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;

    @media (--viewport-lg-min) {
        font-size: 14px;
    }

    &.menu-is-active {
        overflow: hidden;
    }
}

/* Links */
a {
    color: var(--color-blue);
    text-decoration: underline;
    text-decoration-skip-ink: auto;

    &:hover,
    &:focus {
        color: var(--color-pink);
    }
}

/* Horizontal ruler */
hr {
    border: none;
    border-bottom: 1px solid var(--color-gray);
    margin: 30px 0;
}

/* Image handling */
figure {
    margin: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;
    }

    &.contain-width img {
        width: auto;
    }
}

/* Form fix for umbraco */
form {

    & fieldset {
        border: none;
        padding: 0;
    }
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--heading-font-family);
    font-weight: 400;
    line-height: 1.125;
}

/* Background settings */
.background-section {
    padding: 40px 0;
    margin: 0;
}

@media (--viewport-md-min) {
    .background-section {
        padding: 80px 0;
    }    
}

/* List Check */

ul.list-check {
    list-style: none;
    padding: 0;

    & li {
        margin: 4px 0;
        padding-left: 24px;
        position: relative;
        
        &:before {
            content: " ";
            background-size: cover;
            background-image: url("/dist/images/list-check.svg");
            width: 1em;
            height: 1em;
            position: absolute;
            left: 0;
            top: 4px;
        }
    }
}

/* pagination */
.pagination {
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: center;
    font-weight: 600;
    padding: 0;

    & li {
        display: block;
        padding: 0 4px;

        & a {
            position: relative;
            display: inline-block;
            text-decoration: none;
            color: inherit;
            padding: 6px;

            &::after {
                content: '';
                position: absolute;
                display: block;
                background-color: var(--color-blue);
                width: 0;
                height: 1px;
                left: 50%;
                bottom: -4px;
                transform: translateX(-50%);
                transition: .3s;
            }

            &.active,
            &:hover {
                &::after {
                    width: 20px;
                }
            }

            &.disabled {
                cursor: default;
                user-select: none;
                
                &:hover::after {
                    width: 0px;
                }
            }
        }
    }
}

/* Anchor  */
.anchor-point {
    transform: translateY(-78px);
}

@media (--viewport-md-min) {
    .anchor-point {
        transform: translateY(-98px);
    }
}

/* Video plyr */
.plyr__control--overlaid {
    background-color: var(--color-pink);
}

.plyr--video .plyr__control.plyr__tab-focus, 
.plyr--video .plyr__control:hover {
    background-color: var(--color-blue);
}

.plyr--full-ui input[type=range] {
    color: var(--color-pink);
}