.nieuws-slider-section {
    overflow: hidden;
    background-color: var(--color-creme);
    margin: 0;
    padding: 40px 0;

    & .header {
        margin-bottom: 2em;

        & h2 {
            position: relative;
            display: block;
            font-size: 1.2em;
            font-family: var(--heading-font-family);

            & .sth-text {
                position: relative;
                z-index: 2;
                display: inline-block;
                padding-right: 1em;
                background-color: var(--color-creme);
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 140%;
                height: 1px;
                background-color: var(--color-blue);
                top: 50%;
                left: 0;
            }
        }

    }

    & .nieuws-button-group {
        /* padding-left: 5%; */
        margin-bottom: 24px;

        & .nieuwsButton {
            display: inline-block;
            width: 21px;
            height: 8px;
            background-color: var(--color-blue);
            border: none;
            margin-right: 6px;
            cursor: pointer;
            transition: .2s;

            &.active {
                background-color: var(--color-pink);
            }

            &:hover {
                background-color: var(--color-pink);
            }

            &:focus {
                border-radius: 0;
                outline: none;
            }
        }
    }

    & .nieuws-carousel {
        width: calc(100vw - var(--half-gutter-width) );

        & .nieuws-tile {
            width: 94%;
            padding-right: 22px;

            &.active {

                & .image img {
                    opacity: 1;
                }

                & .content {
                    opacity: 1;
                }
            }

            & .image {
                background-color: #000;

                & img {
                    opacity: .2;
                    transition: .3s;
                }
            }

            & .content {
                opacity: .1;
                transition: .3s;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .nieuws-slider-section {
        padding: 60px 0;

        & .header {
            padding: 0 5%;
        }
    
        & .nieuws-button-group {
            padding-left: 5%;
            margin-bottom: 30px;
        }

        & .nieuws-carousel {
            padding-left: 5%;
            width: calc(100vw - var(--half-gutter-width) );

            & .nieuws-tile {
                width: 56%;
                padding-right: 26px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .nieuws-slider-section {
        padding: 90px 0;
    
        & .header {
            margin-bottom: 2.5em;

            & h2 {
                font-size: 1.3em;

                &:after {
                    width: 100%;
                }
            }

        }

        & .nieuws-carousel {
            width: 100vw;

            & .nieuws-tile {
                width: 50%;
                padding-right: 36px;
            }
        }
    }
}

@media (--viewport-lg-min) {
    .nieuws-slider-section {
    
        & .header {

            & h2 {
                font-size: 1.4em;
            }

        }

        & .nieuws-carousel {
            width: calc(100vw - ((100vw - var(--site-width) + var(--gutter-width)) / 2));

            & .nieuws-tile {
                width: calc((var(--site-width) - var(--gutter-width)) / 2);
            }
        }
    }
}