.rooster-overview-section {
    overflow: hidden;

    & .rooster-overview {
        /* padding: 0 5%; */

        & .rooster-days {
            margin: 0 -4px;

            & .flickity-viewport {
                overflow: visible;
            }
    
            & .day-select {
                width: 128px;
                /* padding: 0 4px; */

                &.active .day-select-wrap,
                &:hover .day-select-wrap {
                    color: #fff;
                    background-color: var(--color-blue);
                }

                & .day-select-wrap {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: var(--color-creme);
                    border: none;
                    border-radius: 3px;
                    padding: 14px 16px;
                    margin: 0 4px;
                    transition: .3s;
                    cursor: pointer;
        
                    & .day {
                        display: block;
                        font-family: var(--heading-font-family);
                        font-size: 14px;
                        margin-bottom: 2px;
                    }
        
                    & .date {
                        display: block;
                        font-size: .8em;
                    }
                }
            }

            & .rooster-prev-next-button {
                display: none;
            }
        }

        & .rooster-list {
            margin: 30px 0 0;

            & ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                & li {
                    background-color: var(--color-creme);
                    border-radius: 3px;
                    display: flex;
                    padding: 12px 12px 12px 0;
                    align-items: center;
                    margin-bottom: 12px;
                    transition: .3s;
                    cursor: pointer;

                    &:hover {
                        color: #fff;
                        background-color: var(--color-blue);
                    }

                    & .time {
                        font-family: var(--heading-font-family);
                        font-size: 22px;
                        line-height: 1.1;
                        width: 96px;
                        text-align: center;
                        user-select: none;
                        pointer-events: none;
                    }

                    & .lesson {
                        user-select: none;
                        pointer-events: none;

                        & .lesson-name {
                            font-family: var(--heading-font-family);
                            font-size: 18px;
                        }

                        & .lesson-trainer {
                            font-size: .8em;
                        }
                    }
                }
            }
        }
    }
}

.rooster-modal-overlay {
    position: fixed;
    z-index: 499;
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &.active {
        display: flex;
    }

    & .rooster-modal-bg {
        position: fixed;
        z-index: 42;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    & .rooster-wrap {

        & .rooster-modal {
            max-width: 700px;
            position: relative;
            z-index: 44;
            margin: 80px 15px;
            background-color: var(--color-creme);
            border-radius: 3px;
            overflow: hidden;

            & .close-button {
                position: absolute;
                top: 20px;
                right: 26px;
                z-index: 9;
                content: '';
                height: 31px;
                width: 20px;
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10 fa-xs'%3E%3Cpath fill='white' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' class=''%3E%3C/path%3E%3C/svg%3E");
                background-size: 20px 31px;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }

            & .image {

                & img {
                    display: block;
                    width: 100%;
                }
            }

            & .top {
                border-radius: 3px;
                display: flex;
                padding: 24px 24px 0;
                align-items: center;
                
                & .time {
                    font-family: var(--heading-font-family);
                    font-size: 22px;
                    line-height: 1.1;
                    text-align: center;
                    margin-right: 24px
                }

                & .lesson {

                    & .lesson-name {
                        font-family: var(--heading-font-family);
                        font-size: 18px;
                    }

                    & .lesson-trainer {
                        font-size: .8em;
                    }
                }
            }

            & .bottom {
                padding: 24px;

                & p {
                    margin-top: 0;
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .rooster-overview-section {

        & .rooster-overview {
            padding: 0 5%;
        }
    }
}

@media (--viewport-md-min) {
    .rooster-overview-section {

        & .rooster-overview {
    
            & .rooster-days {

                & .flickity-viewport {
                    overflow: hidden;
                }
        
                & .day-select {
                    width: calc(100% / 6);

                    & .day-select-wrap {
                        padding: 16px 22px;
        
                        & .day {
                            font-size: 16px;
                            margin-bottom: 2px;
                        }
            
                        & .date {
                            font-size: 14px;
                            font-size: .9em;
                        }
                    }
                }

                & .rooster-prev-next-button {
                    display: block;
                    content: '';
                    position: absolute;
                    background-color: var(--color-creme);
                    /* width: 79px; */
                    width: calc(100% / 6 - 4px);
                    padding: 0;
                    height: 79px;
                    border-radius: 3px;
                    background-size: 25px 16px;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    top: 0;
                    transform: scaleX(1);
                    transition: .3s;
                    cursor: pointer;
    
                    &:hover {
                        background-color: var(--color-blue);
                    
                        &.previous {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='66' viewBox='0 0 101 66' fill='#ffffff'%3E%3Cpath d='M31.6707 0.523919L34.3982 3.4748C35.0437 4.17321 35.0437 5.30563 34.3982 6.00419L13.0277 29.1249H99.3469C100.26 29.1249 101 29.9257 101 30.9134V35.0866C101 36.0743 100.26 36.8751 99.3469 36.8751H13.0277L34.3982 59.9959C35.0437 60.6943 35.0437 61.8268 34.3982 62.5253L31.6707 65.4762C31.0252 66.1746 29.9785 66.1746 29.3328 65.4762L0.484161 34.2647C-0.161377 33.5663 -0.161377 32.4339 0.484161 31.7354L29.3329 0.523919C29.9785 -0.17464 31.0252 -0.17464 31.6707 0.523919Z' fill='%23fffff'/%3E%3C/svg%3E");
                        }
                        
                        &.next {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='66' viewBox='0 0 101 66' fill='none'%3E%3Cpath d='M69.3293 0.523919L66.6018 3.4748C65.9563 4.17321 65.9563 5.30563 66.6018 6.00419L87.9723 29.1249H1.65311C0.740178 29.1249 0 29.9257 0 30.9134V35.0866C0 36.0743 0.740178 36.8751 1.65311 36.8751H87.9723L66.6018 59.9959C65.9563 60.6943 65.9563 61.8268 66.6018 62.5253L69.3293 65.4762C69.9748 66.1746 71.0215 66.1746 71.6672 65.4762L100.516 34.2647C101.161 33.5663 101.161 32.4339 100.516 31.7354L71.6671 0.523919C71.0215 -0.17464 69.9748 -0.17464 69.3293 0.523919Z' fill='%23ffffff'/%3E%3C/svg%3E");
                        }
                    }
    
                    &.disabled {
                        transform: scaleX(0);
                    }
                    
                    &.previous {
                        left: 0px;
                        transform-origin: left;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='66' viewBox='0 0 101 66' fill='#ffffff'%3E%3Cpath d='M31.6707 0.523919L34.3982 3.4748C35.0437 4.17321 35.0437 5.30563 34.3982 6.00419L13.0277 29.1249H99.3469C100.26 29.1249 101 29.9257 101 30.9134V35.0866C101 36.0743 100.26 36.8751 99.3469 36.8751H13.0277L34.3982 59.9959C35.0437 60.6943 35.0437 61.8268 34.3982 62.5253L31.6707 65.4762C31.0252 66.1746 29.9785 66.1746 29.3328 65.4762L0.484161 34.2647C-0.161377 33.5663 -0.161377 32.4339 0.484161 31.7354L29.3329 0.523919C29.9785 -0.17464 31.0252 -0.17464 31.6707 0.523919Z' fill='%23021A2B'/%3E%3C/svg%3E");
                    }
                    
                    &.next {
                        right: 0px;
                        transform-origin: right;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='66' viewBox='0 0 101 66' fill='none'%3E%3Cpath d='M69.3293 0.523919L66.6018 3.4748C65.9563 4.17321 65.9563 5.30563 66.6018 6.00419L87.9723 29.1249H1.65311C0.740178 29.1249 0 29.9257 0 30.9134V35.0866C0 36.0743 0.740178 36.8751 1.65311 36.8751H87.9723L66.6018 59.9959C65.9563 60.6943 65.9563 61.8268 66.6018 62.5253L69.3293 65.4762C69.9748 66.1746 71.0215 66.1746 71.6672 65.4762L100.516 34.2647C101.161 33.5663 101.161 32.4339 100.516 31.7354L71.6671 0.523919C71.0215 -0.17464 69.9748 -0.17464 69.3293 0.523919Z' fill='%23021A2B'/%3E%3C/svg%3E");
                    }
                }
            }

            & .rooster-list {
                margin: 60px 0 0;
    
                & ul {
    
                    & li {
                        padding: 20px 20px 20px 0;
                        margin-bottom: 22px;

                        & .time {
                            font-size: 36px;
                            width: 168px;
                        }
    
                        & .lesson {
    
                            & .lesson-name {
                                font-size: 24px;
                            }

                            & .lesson-trainer {
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

    .rooster-modal-overlay {
    
        & .rooster-wrap {
    
            & .rooster-modal {
    
                & .top {
                    
                    & .time {
                        font-size: 32px;
                        margin-right: 24px
                    }
    
                    & .lesson {
    
                        & .lesson-name {
                            font-size: 22px;
                        }
    
                        & .lesson-trainer {
                            font-size: 1em;
                        }
                    }
                }
    
                & .bottom {
                    padding: 24px;
                }
            }
        }
    }
}
