.contact-section {

    .wrapper {

        .text {
            margin-bottom: 30px;
    
            h2 {
                font-size: 1.9em;
                margin-top: 0;
            }

            ul {
                list-style-type: none;
                padding: 0;

                li {

                    .contact-wrap {
                        display: flex;
                        margin-bottom: 4px;

                        i {
                            color: var(--color-pink);
                            min-width: 24px;
                            line-height: inherit;
                        }
    
                        a {
                            color: inherit;
                            text-decoration: none;
    
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

.openingstijden-modal-overlay {
    position: fixed;
    z-index: 499;
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &.active {
        display: flex;
    }

    & .openingstijden-modal-bg {
        position: fixed;
        z-index: 42;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    & .openingstijden-wrap {
        max-height: 100%;

        & .openingstijden-modal {
            max-width: 700px;
            position: relative;
            z-index: 44;
            margin: 60px 15px;
            background-color: var(--color-creme);
            border-radius: 3px;
            /* overflow: hidden; */

            & .close-button {
                position: absolute;
                top: 20px;
                right: 26px;
                z-index: 9;
                content: '';
                height: 31px;
                width: 20px;
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10 fa-xs'%3E%3Cpath fill='#021a2b' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' class=''%3E%3C/path%3E%3C/svg%3E");
                background-size: 20px 31px;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }
            
            .text {
                padding: 30px;
                width: 100%;

                h2 {
                    font-size: 1.9em;
                }
            }
        }
    }
}

.map-section {
    margin: 0;

    iframe {
        display: block;
    }
}

@media (--viewport-sm-min) {
    .contact-section {

        .wrapper {
            padding: 0 5%;
        }
    }
}

@media (--viewport-md-min) {
    .contact-section {

        .wrapper {
    
            .text {
                margin-bottom: 10px;
        
                h2 {
                    font-size: 2.25em;
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .contact-section {

        .wrapper {
    
            .text {
        
                h2 {
                    font-size: 2.85em;
                }
            }
        }
    }
}