.button {
    background-color: transparent;
    border: none;
    color: var(--color-blue);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.25;
    padding: 10px 18px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: .2s;
    border: 1px solid var(--color-blue);
    border-radius: 3px;
    margin: 8px 12px 8px 0;

    & a {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        background-color: var(--color-blue);
        color: var(--color-white);
        border: 1px solid var(--color-blue);
    }

    &.alt {
        background-color: transparent;
        color: var(--color-blue);
        border: 1px solid var(--color-pink);

        &:hover {
            background-color: var(--color-pink);
            color: var(--color-white);
            border: 1px solid var(--color-pink);
        }
    }

    &.border {
        background-color: var(--color-secondary);
        color: var(--color-secondary-offset);
        border: 1px solid var(--color-primary);

        &:hover {
            background-color: var(--color-primary);
            color: var(--color-primary-offset);
            border: 1px solid var(--color-primary);
        }
    }
}