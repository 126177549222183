.center-form-section {
    position: relative;

    &.background:after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 70%;
        top: 15%;
        background-color: var(--color-blue);
    }

    .center-form {
        position: relative;
        z-index: 2;
        padding: 25px;
        background-color: var(--color-creme);

        .center-form-wrap {
            max-width: 800px;
            margin: 0 auto;

            h2 {
                font-size: 1.9em;
                margin: .4em 0 .8em;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .center-form-section {
    
        .center-form {
            padding: 60px;
    
            .center-form-wrap {
    
                h2 {
                    
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .center-form-section {
    
        .center-form {
            padding: 90px 60px;
    
            .center-form-wrap {
    
                h2 {
                    font-size: 2.25em;
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .center-form-section {
    
        .center-form {
    
            .center-form-wrap {
    
                h2 {
                    font-size: 2.85em;
                }
            }
        }
    }
}