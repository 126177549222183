.video-section {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        top: 15%;
        width: 100%;
        height: 70%;
        background-color: var(--color-blue);
    }

    & .wrap {
        position: relative;
        z-index: 2;

        & .video-text-overlay {
            display: none;
        }

        & .video-wrap {
            width: 100%;

        }
    }
}

@media (--viewport-ms-min) {
    .video-section {
    
        & .wrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
    
            & .video-text-overlay {
                display: block;
                position: absolute;
                z-index: 2;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
    
                & h2 {
                    color: #fff;
                    font-size: 2.4em;
                }
            }
    
            & .video-wrap {
                width: 84%;
    
            }
        }
    }
}

@media (--viewport-sm-min) {
    .video-section {
    
        & .wrap {
    
            & .video-text-overlay {
                margin-left: 5%;
    
                & h2 {
                    font-size: 3.6em;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .video-section {
    
        & .wrap {
    
            & .video-text-overlay {
    
                & h2 {
                    font-size: 4.6em;
                }
            }
        }
    }
}