.hero-section {
    margin: 0;
    min-height: 80vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: var(--color-blue);

    &:before {
        content: '';
        position: absolute;
        z-index: 4;
        height: 24px;
        width: calc(100vw - var(--half-gutter-width) + 4px);
        background-color: #fff;
        border-top-left-radius: 3px;
        bottom: 0;
        right: 0;
    }

    & .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;

        & figure {
            height: 100%;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    & .lijntje {
        /* content: ''; */
        position: absolute;
        z-index: 2;
        /* width: calc(66vw + 100px); */
        /* height: calc(80vh + 100px); */
        /* border: 1px solid var(--color-pink); */
        /* border-bottom-right-radius: 100%; */
        /* top: -102px; */
        /* left: -102px; */

        width: 60%;
        height: 70%;
        top: -2px;
        left: -2px;

        & svg {
            position: absolute;
            width: 100%;
            height: 100%;

            & path {
                stroke-dasharray: 3000;
                animation: dash 6s normal;
            }
        }
    }

    & .container {
        max-width: var(--site-width);

        & .content {
            position: relative;
            z-index: 5;
            color: #fff;
            /* max-width: 1050px; */
            margin: 100px 0 100px;

            & h1 {
                font-size: 4em;
                color: #fff;
                margin: 0 0 40px;
            }

            .hero-buttons {

                .button {
                    background-color: var(--color-pink);
                    color: #fff;
                    border: 2px solid var(--color-pink);

                    &:hover {
                        background-color: #fff;
                        color: var(--color-blue);
                        border: 2px solid #fff;
                    }

                    &.alt {
                        background-color: #fff;
                        color: var(--color-blue);
                        border: 2px solid #fff;

                        &:hover {
                            background-color: var(--color-pink);
                            color: #fff;
                            border: 2px solid var(--color-pink);
                        }
                    }
                }
            }

            & .location-select {
                position: relative;
                display: inline-block;

                & .select-opt {
                    display: inline-block;
                    font-weight: 600;
                    text-transform: uppercase;
                    background-color: var(--color-pink);
                    padding: 12px 22px;
                    line-height: 1.25;
                    border-radius: 3px;
                    cursor: pointer;
                    transition: .3s;

                    &.active {
                        border-top-right-radius: 0px;
                        border-top-left-radius: 0px;
                    }

                    &:hover {
                        background-color: var(--color-blue);
                    }

                    & .select-opt-wrap {
                        display: flex;
                        align-items: center;

                        & .select-opt-text {
                            margin-right: 1em;
                        }

                        & svg {
                            width: 16px;
                        }
                    }
                }

                & .select-list {
                    position: absolute;
                    bottom: 45px;
                    width: 100%;
                    transform: scaleY(0);
                    transform-origin: bottom;
                    transition: .2s;

                    &.active {
                        transform: scaleY(1);

                        & li {
                            opacity: 1;
                        }
                    }

                    & ul {
                        list-style: none;
                        margin: 0;
                        padding: 4px 0 12px;
                        background-color: var(--color-blue);
                        border-top-right-radius: 3px;
                        border-top-left-radius: 3px;
                        font-weight: 600;

                        & li {
                            /* margin: 8px 0 0 0; */
                            opacity: 0;
                            transition: transform .3s .2s, background-color .3s;

                            &:hover {
                                background-color: var(--color-pink);
                            }

                            & a {
                                color: inherit;
                                text-decoration: none;
                                transition: .2s;
                                display: block;
                                padding: 4px 22px;
                            }
                        }
                    }
                }
            }

            & .hero-controls {
                position: absolute;
                bottom: -50px;
                display: flex;

                & .hero-volume {
                    position: relative;

                    & .hero-volume-control {
                        cursor: pointer;
                        content: '';
                        width: 36px;
                        height: 36px;
                        background-position: left;
                        background-size: 30px 30px;
                        background-repeat: no-repeat;
                        margin-right: 1em;
                        transition: .3s;

                        &.active {
                            display: none;
                        }

                        &.hero-mute-video {
                            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='volume-up' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' %3E%3Cpath fill='%23fff' d='M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zm233.32-51.08c-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 78.98-39.55 152.09-105.82 195.58-11.17 7.32-14.29 22.34-6.95 33.5 7.04 10.71 21.93 14.56 33.51 6.95C528.27 439.58 576 351.33 576 256S528.27 72.43 448.35 19.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.54 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z' class=''%3E%3C/path%3E%3C/svg%3E");

                            &:hover {
                                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='volume-up' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' %3E%3Cpath fill='%23E5007B' d='M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zm233.32-51.08c-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 78.98-39.55 152.09-105.82 195.58-11.17 7.32-14.29 22.34-6.95 33.5 7.04 10.71 21.93 14.56 33.51 6.95C528.27 439.58 576 351.33 576 256S528.27 72.43 448.35 19.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.54 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z' class=''%3E%3C/path%3E%3C/svg%3E");
                            }
                        }

                        &.hero-unmute-video {
                            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='volume-off' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23fff' d='M215 71l-89 89H24a24 24 0 0 0-24 24v144a24 24 0 0 0 24 24h102.06L215 441c15 15 41 4.47 41-17V88c0-21.47-26-32-41-17z' class=''%3E%3C/path%3E%3C/svg%3E");
                            background-position: -7px 50%;

                            &:hover {
                                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='volume-off' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23E5007B' d='M215 71l-89 89H24a24 24 0 0 0-24 24v144a24 24 0 0 0 24 24h102.06L215 441c15 15 41 4.47 41-17V88c0-21.47-26-32-41-17z' class=''%3E%3C/path%3E%3C/svg%3E");
                            }
                        }
                    }
                }

                & .hero-replay {
                    cursor: pointer;
                    content: '';
                    width: 36px;
                    height: 36px;
                    background-position: left;
                    background-size: 22px 22px;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='redo' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z' class=''%3E%3C/path%3E%3C/svg%3E");
                    transition: .3s;

                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='redo' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23E5007B' d='M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z' class=''%3E%3C/path%3E%3C/svg%3E");
                    }
                }
            }
        }
    }
}


@media (--viewport-sm-min) {
    .hero-section {

        & .container {

            & .content {
                padding: 0 5%;

                & .hero-controls {
                    right: 5%;
                    bottom: 0;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .hero-section {
        min-height: 100vh;

        &:before {
            height: 36px;
            width: calc(100vw - var(--half-gutter-width) );
        }

        & .lijntje {
            /* width: calc(48vw + 300px); */
            /* height: calc(80vh + 300px); */
            /* top: -302px; */
            /* left: -302px; */

            width: 50%;
            height: 80%;
        }

        & .container {

            & .content {
                margin: 140px 0;

                & h1 {
                    font-size: 6em;
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .hero-section {

        &:before {
            height: 46px;
            width: calc(100vw - ((100vw - var(--site-width) + var(--gutter-width) + 12px) / 2));
        }

        & .container {

            & .content {
                margin: 180px 0;

                & h1 {
                    font-size: 7em;
                }
            }
        }
    }
}

/* ipad */
@media (min-width: 768px) and (min-height: 768px) and (max-width: 992px) {
    .hero-section {
        min-height: 55vh;
    }
}

/* ipad Pro */
@media (min-width: 1000px) and (min-height: 1000px) and (max-width: 1200px) {
    .hero-section {
        min-height: 65vh;
    }
}