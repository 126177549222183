.inschrijfpagina-carousel {
    margin: 0 0 40px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(40% + 60px);
        left: 0;
        top: -60px;
        background-color: var(--color-blue);
    }

    .sis-carousel {

        .carousel-cell {
            width: 100%;
            max-width: 200px;
            padding: 0 6px;
            transform: translateX(-63px);

            &.hidden {
                /* display: none; */
                position: absolute;
                width: 0px;
                padding: 0;
            }

            img {
                display: block;
                width: 100%;
            }
        }
    }

    .switch-locations {
        display: none;

        img {
            display: none;
        }
    }
}

.inschrijfpagina-section {
    margin-top: 40px;

    .form-footer-buttons {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        input[type=submit] {
            padding: 12px;
        }

        .button {
            margin: 8px 0 12px;
            padding: 12px;
            line-height: 1.25;
            font-size: 16px;
            font-family: Montserrat,Helvetica,Arial,"sans-serif";

            &.button-pink {
                background-color: #e5007b;
                color: #fff;
                border: 1px solid #e5007b;

                &:hover {
                    background-color: #021A2B;
                    border: 1px solid #021A2B;
                }
            }
        }
    }

    .actie-label {
        width: 100%;
        background-color: var(--color-pink);
        color: #fff;
        cursor: default;
        margin: 0 0 30px;

        &:hover {
            color: #fff;
        }
    }

    .ips-form-wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .ips-header h1 {
            font-size: 26px;
        }

        .ips-form {
            width: 100%;
            max-width: 760px;

            .row {
                margin: 0 -12px;

                [class^=col-] {
                    padding: 0 12px;
                }
            }

            /* .button--pink {
                cursor: default;
                width: 100%;
                color: #fff;
                background-color: var(--color-pink);
                border:  1px solid var(--color-pink);
            } */

            .club-select {
                margin: 26px 0;
            }

            .ips-abo-select {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: space-between;
                margin: 26px 0 26px;

                h3 {
                    width: 100%;
                    font-size: 20px;
                    margin: 0 0 30px;
                }

                .ips-radio-select {
                    width: 100%;
                    position: relative;
                    background-color: var(--color-creme);
                    border-radius: 3px;
                    margin-bottom: 30px;

                    input {
                        /* visibility: hidden; */
                        position: absolute;
                        z-index: -1;
                        top: 40px;
                        left: 10%;

                        &:hover ~ .ips-radio-label,
                        &:checked ~ .ips-radio-label {
                            background-color: var(--color-blue);
                            color: #fff;

                            .ipsrl-button .button {
                                color: #fff;
                                border: 1px solid #fff;
                            }
                        }

                        &:checked ~ .ips-radio-label .ipsrl-title:after {
                            opacity: 1;
                        }
                    }

                    .ips-radio-label {
                        border-radius: 3px;
                        width: 100%;
                        margin: 0;
                        padding: 22px;
                        cursor: pointer;
                        transition: .2s;
                        height: 100%;

                        .ipsrl-most-picked {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            background-color: var(--color-pink);
                            border-radius: 3px;
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            white-space: nowrap;
                            padding: 3px 8px;
                            transform: translate(-50%, -50%);
                        }

                        .ipsrl-title {
                            display: inline-block;
                            font-family: var(--heading-font-family);
                            font-size: 24px;
                            margin-bottom: 4px;
                            transition: .1s;

                            &:after {
                                display: inline-block;
                                margin-left: 8px;
                                width: 20px;
                                height: 1em;
                                content: '';
                                color: var(--color-pink);
                                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23E5007B' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                                background-size: 16px 1em;
                                background-position: 0px 4px;
                                background-repeat: no-repeat;
                                opacity: 0;
                                transition: .1s;
                            }
                        }

                        .ipsrl-price {
                            font-family: var(--heading-font-family);
                            font-size: 16px;
                            margin-bottom: 4px;
                            transition: .1s;
                        }

                        .ipsrl-duration {
                            font-size: 12px;
                            margin: 10px 0 14px;
                            transition: .1s;
                        }

                        .ipsrl-actie {
                            background-color: var(--color-pink);
                            color: #fff;
                            font-weight: 700;
                            font-size: 13px;
                            width: calc(100% + 16px);
                            margin: 0 -8px -8px;
                            padding: 4px 8px;
                            border-radius: 3px 3px 3px 3px;
                        }
                    }
                }
            }

            .ips-abo-upgrades {
                margin-bottom: 34px;

                .custom-checkbox label {
                    font-weight: 400;
                    font-size: 12px;
                    padding-left: 32px;
                    max-width: 500px;

                    &:before {
                        top: 6px;
                    }

                    &:after {
                        top: 9px;
                    }

                    span {
                        font-family: var(--heading-font-family);
                        font-size: 16px;
                    }
                }
            }

            .new-inschrijf-list {
                display: flex;
                flex-wrap: wrap;
                background-color: #F9F7F3;
                border-radius: 3px;
                width: 100%;
                padding: 22px 22px 0;

                .ipsrl-list  {
                    margin-bottom: auto;
                    transition: .1s;
                    padding: 0 0 22px;
                    width: 100%;

                    h4 {
                        margin: 4px 0 18px;
                        font-size: 14px;

                        &.ipsrl-mobile-title {
                            opacity: 0;
                            height: 0px;
                            margin: -22px 0 0 0;
                        }
                    }

                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        font-size: 14px;

                        & li {
                            position: relative;
                            padding-left: 20px;
                            margin-bottom: 4px;

                            & a {
                                color: inherit;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 20px;
                                height: 100%;
                                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23E5007B' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                                background-size: 18px 1em;
                                background-position: 0px 4px;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }

        .ips-abonement-wrap {

            .ips-abo-overview {
                background-color: var(--color-creme);
                border-radius: 3px;
                display: flex;
                flex-direction: column;
                width: 300px;
                padding: 32px;

                .ipsao-info {
                    margin-bottom: 12px;
                }

                .ipsao-upgrades {
                    margin-bottom: 12px;
                }

                .button {
                    width: 100%;
                    margin: 0 0 8px;
                    font-size: 12px;
                    text-align: center;
                    cursor: default;

                    &--blue {
                        color: #fff;
                        background-color: var(--color-blue);
                    }

                    &--white {
                        color: inherit;
                        background-color: #fff;
                        border: none;
                    }

                    /* &--pink {
                        color: #fff;
                        background-color: var(--color-pink);
                        border:  1px solid var(--color-pink);
                    } */
                }

                .ipsao-customer {

                    h4 {
                        font-size: 18px;
                        margin: 12px 0 16px;
                    }

                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0 0 16px;
                    }
                }

                .ipsao-price-overview {
                    border-top: 1px solid var(--color-blue);
                    margin-top: 6px;
                    padding-top: 14px;

                    .ipsao-price {
                        font-family: var(--heading-font-family);
                        font-size: 32px;
                    }

                    .ipsao-duration {
                        font-family: var(--heading-font-family);
                        font-size: 18px;
                    }

                    .ipsao-abo {
                        font-size: 12px;
                        margin-top: 14px;
                    }
                }
            }
        }
    }
}

.ips-abo-wrap-toggle {
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;

    .button--blue {
        text-align: center;
        width: 100%;
        color: #fff;
        background-color: var(--color-blue);
        border:  1px solid var(--color-blue);

        .hide {
            display: none;
        }
    }

    &.active .button--blue .hide {
        display: block;
    }

    &.active .button--blue .show {
        display: none;
    }
}

.inschrijfpagina-modal-overlay {
    position: fixed;
    z-index: 499;
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &.active {
        display: flex;
    }

    & .inschrijfpagina-modal-bg {
        position: fixed;
        z-index: 42;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    & .inschrijfpagina-wrap {

        & .inschrijfpagina-modal {
            max-width: 700px;
            position: relative;
            z-index: 44;
            margin: 80px 15px;
            background-color: var(--color-creme);
            border-radius: 3px;
            overflow: hidden;

            & .close-button {
                position: absolute;
                top: 20px;
                right: 26px;
                z-index: 9;
                content: '';
                height: 31px;
                width: 20px;
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10 fa-xs'%3E%3Cpath fill='#021a2b' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' class=''%3E%3C/path%3E%3C/svg%3E");
                background-size: 20px 31px;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }

            .text {
                padding: 30px;
                width: 100%;
                max-width: 500px;
            }
        }
    }
}

.file-input {
    margin: 12px 0;
    position: relative;

    label {
        position: absolute;
        margin: 0;
        top: 12px;
        left: 12px;
        color: grey;
        font-size: 16px;
    }

    input {
        border: solid 1px var(--color-blue);
        padding: 46px 12px 12px 12px;
    }
}

.signature-input {
    position: relative;
    margin: 12px 0;

    .signature-input-wrap {
        position: relative;

        .signature-pad {
            border: 1px solid #021a2b;
        }

        .signature-input-title {
            position: absolute;
            top: 12px;
            left: 12px;
            color: gray;
            font-size: 14px;
            pointer-events: none;
        }
    }

    .signature-clear {
        display: inline-block;
        padding: 6px 14px;
        background-color: var(--color-blue);
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
    }

    .signature-hidden-input textarea {
        position: absolute;
        opacity: 0;
        height: 1px;
        width: 1px;
        padding: 0;
        margin: 0;
        top: 36px;
        left: 62px;
    }
}

@media (--viewport-sm-max) {
    .ips-abonement-wrap {
        display: none;
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        justify-content: center;
        overflow: scroll;

        &.active {
            display: flex;
        }

        .ips-abo-overview {
            margin: 110px 0 70px;
            height: fit-content;
        }
    }
}

@media (--viewport-sm-min) {
    .inschrijfpagina-carousel {
        margin: 0 0 40px 0;

        .sis-carousel {

            .carousel-cell {
                padding: 0 10px;
                max-width: 280px;
                transform: translateX(0);
            }
        }
    }

    .inschrijfpagina-section {
        margin-top: 80px;

        .ips-form-wrap {

            .ips-form {

                .ips-abo-select {
                    flex-direction: row;

                    .ips-radio-select {
                        width: 32%;
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .inschrijfpagina-carousel {
        margin: 0 0 60px;

        .sis-carousel {

            .carousel-cell {
                padding: 0 10px;
                max-width: 330px;
            }
        }
    }

    .file-input {

        input {
            padding: 12px 0 12px 165px;
        }
    }

    .inschrijfpagina-section {
        margin-top: 80px;

        .actie-label {
            margin: 0 0 36px;
        }

        .ips-form-wrap {
            flex-direction: row;

            .ips-header h1 {
                font-size: 36px;
            }

            .ips-form {
                .club-select {
                    margin: 36px 0;
                }

                .ips-abo-select {
                    /* flex-direction: row; */
                    margin: 36px 0 46px;

                    h3 {
                        font-size: 24px;
                        margin: 0 0 40px;
                    }

                    .ips-radio-select {
                        /* width: 32%; */

                        .ips-radio-label {

                        }
                    }
                }

                .ips-abo-upgrades {
                    margin-bottom: 44px;

                    .custom-checkbox label {

                        span {
                            font-size: 18px;
                        }
                    }
                }

                .new-inschrijf-list {
                    padding: 0;

                    .ipsrl-list  {
                        width: 33%;
                        padding: 22px 0 22px 22px;

                        h4 {

                            &.ipsrl-mobile-title {
                                height: auto;
                                margin: 4px 0 18px;
                            }
                        }
                    }
                }
            }

            .ips-abonement-wrap {
                margin-left: 30px;

                .ips-abo-overview {
                    position: sticky;
                    top: 120px;
                    background-color: var(--color-creme);
                    border-radius: 3px;
                    display: flex;
                    flex-direction: column;
                    width: 260px;
                    padding: 18px;

                    .button {
                        width: 100%;
                        margin: 0 0 6px;
                        font-size: 11px;
                        text-align: center;
                        cursor: default;

                        &--blue {
                            color: #fff;
                            background-color: var(--color-blue);
                        }

                        &--white {
                            color: inherit;
                            background-color: #fff;
                            border: none;
                        }
                    }

                    .ipsao-customer {

                        h4 {
                            font-size: 16px;
                            margin: 10px 0 14px;
                        }

                        ul {
                            list-style-type: none;
                            padding: 0;
                            margin: 0 0 12px;
                        }
                    }

                    .ipsao-price-overview {
                        border-top: 1px solid var(--color-blue);
                        margin-top: 6px;
                        padding-top: 14px;

                        .ipsao-price {
                            font-family: var(--heading-font-family);
                            font-size: 28px;
                        }

                        .ipsao-duration {
                            font-family: var(--heading-font-family);
                            font-size: 16px;
                        }

                        .ipsao-abo {
                            font-size: 12px;
                            margin-top: 14px;
                        }
                    }
                }
            }
        }
    }

    .ips-abo-wrap-toggle {
        display: none;
    }
}

@media (--viewport-lg-min) {
    .inschrijfpagina-carousel {

        .sis-carousel {

            .carousel-cell {
                max-width: 400px;
            }
        }
    }

    .inschrijfpagina-section {

        .ips-form-wrap {

            .ips-header h1 {
                font-size: 42px;
            }

            .ips-abonement-wrap {

                .ips-abo-overview {
                    top: 120px;
                    width: 300px;
                    padding: 32px;

                    .button {
                        margin: 0 0 8px;
                        font-size: 12px;
                    }

                    .ipsao-customer {

                        h4 {
                            font-size: 18px;
                            margin: 12px 0 16px;
                        }

                        ul {
                            margin: 0 0 16px;
                        }
                    }

                    .ipsao-price-overview {

                        .ipsao-price {
                            font-size: 32px;
                        }

                        .ipsao-duration {
                            font-size: 18px;
                        }

                        .ipsao-abo {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}