.small-image-slider-section {
    margin: 30px 0;

    .sis-carousel {

        .carousel-cell {
            width: 100%;
            max-width: 120px;
            padding: 0 6px;
            transform: translateX(-63px);

            &.hidden {
                /* display: none; */
                position: absolute;
                width: 0px;
                padding: 0;
            }

            img {
                display: block;
                width: 100%;
            }
        }
    }

    .switch-locations {
        display: none;

        img {
            display: none;
        }
    }
}

@media (--viewport-sm-min) {
    .small-image-slider-section {
        margin: 60px 0;

        .sis-carousel {

            .carousel-cell {
                padding: 0 10px;
                max-width: 180px;
                transform: translateX(0);
            }
        }
    }
}

@media (--viewport-md-min) {
    .small-image-slider-section {
        margin: 90px 0;

        .sis-carousel {

            .carousel-cell {
                padding: 0 10px;
                max-width: 240px;
            }
        }
    }
}

@media (--viewport-lg-min) {
    .small-image-slider-section {

        .sis-carousel {

            .carousel-cell {
                max-width: 300px;
            }
        }
    }
}