.module-quote {

    & blockquote {
        color: black;
        quotes: "\201C" "\201D";
        display: inline-block;
        position: relative;
        padding: 22px;
        margin: 1em 20px;
        text-align: left;
        font-size: 1.3em;
        line-height: 32px;

        & p {
            margin: 0;
        }

        & .author {
            font-size: 1.1rem;
        }
        
        &:before {
            display: block;
            position: absolute;
            top: 8px;
            left: 4px;
            content: "\"";
            color: var(--color-primary);
            font-size: 52px !important;
        }
    }
}

@media (--viewport-md-min) {
    .module-quote {

        & blockquote {
            padding: 38px;
            margin: 1em 40px;
            font-size: 2em;
            line-height: 44px;
            
            &:before {
                top: 10px;
                left: 10px;
                font-size: 60px !important;
            }
        }
    }
}