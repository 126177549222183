.cta-simple-section {
    margin: 0;
    padding: 20px 0;
    background-color: var(--color-blue);

    & .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .ctass-text {
            margin-right: 40px;
            color: #fff;

            & h2 {
                font-size: 1.9em;
            }
        }

        & .ctass-button {
                
            & .button {
                color: #fff;
                white-space: nowrap;
                margin-right: 0;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .cta-simple-section {
        padding: 40px 0;
    
        & .wrap {
            padding: 0 5%;
    
            & .ctass-text {
                
            }
        }
    }
}

@media (--viewport-md-min) {
    .cta-simple-section {
        padding: 50px 0;
    
        & .wrap {
            flex-direction: row;
            align-items: center;
    
            & .ctass-text {
                
                & h2 {
                    font-size: 2.25em;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .cta-simple-section {
    
        & .wrap {
    
            & .ctass-text {
                
                & h2 {
                    font-size: 2.45em;
                }
            }
        }
    }
}