.module-fotoboek {

    & .target-photo {
        cursor: zoom-in;
    }
    
    .lightbox {
        cursor: zoom-out;
        display: none;
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100%;
        text-align: center;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);

        & img {
            max-width: 90%;
            max-height: 80%;
            margin-top: 2%;
        }

        &:target {
            outline: none;
            /* display: block; */
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}