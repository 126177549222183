.actie-slider-section {
    position: relative;
    z-index: 4;
    margin-top: 0;
    overflow: hidden;

    & .flickity-viewport {
        transition: height 0.2s;
    }

    & .actie-carousel {
        position: relative;
        overflow: hidden;
        width: calc(100vw - var(--half-gutter-width) );
        padding-left: 15px;

        & .carousel-cell {
            width: 90%;
            padding-right: 15px;

            &.is-selected {
                position: relative;
                z-index: 5;

                & .image {
                    cursor: default;

                    & figure {
                        /* cursor: default; */
    
                        & img {
                            /* cursor: default; */
                            user-select: none;
                            pointer-events: none;
                        }
                    }
                }
            }

            &.hidden {

                & .image img {
                    opacity: .4;
                }

                & .content {
                    opacity: 0;
                    user-select: none;
                    pointer-events: none;
                }
            }

            & .image {
                background-color: #000;
                cursor: pointer;

                & img {
                    transition: .6s;
                }
            }

            & .content {
                transition: .6s ease-in-out;

                & .content-wrap {

                    & .text {
                        
                        & .actie-p {
                            display: none;
                        }

                        & .actie-button {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    & .actie-button-group {
        padding-left: 5%;

        & .actieButton {
            display: inline-block;
            width: 21px;
            height: 8px;
            background-color: var(--color-blue);
            border: none;
            margin-right: 6px;
            cursor: pointer;
            transition: .2s;

            &.active {
                background-color: var(--color-pink);
                user-select: none;
                pointer-events: none;
            }

            &:hover {
                background-color: var(--color-pink);
            }

            &:focus {
                border-radius: 0;
                outline: none;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .actie-slider-section {
        margin-bottom: 40px;
    
        & .actie-carousel {
    
            & .carousel-cell {
                width: 48%;
    
                & .content {
                    width: calc(200% + 20px);
                    transition: .6s ease-in-out;
    
                    & .content-wrap {
                        display: flex;
                        justify-content: space-between;
    
                        & .title {
                            width: calc(50% - 10px);
    
                            & h2 {
                                font-size: 2em;
                                margin: 26px 0 36px;
                            }
                        }
    
                        & .text {
                            width: calc(50% - 10px);
                            
                            & .actie-p {
                                display: block;
                                margin-top: 26px;
                            }
                        }
                    }
                }
            }
        }

        & .actie-button-group {
            width: 50%;
            padding-left: 14px;
            transform: translateY(-30px);
        }
    }
}

@media (--viewport-md-min) {
    .actie-slider-section {
        margin-bottom: 80px;
    
        & .actie-carousel {
            padding-left: 30px;
    
            & .carousel-cell .content .content-wrap {

                & .title h2 {
                    font-size: 2.3em;
                    margin: 30px 0 42px;
                }

                & .text .actie-p {
                    margin-top: 36px;
                }
            }
        }

        & .actie-button-group {
            padding-left: 30px;
        }
    }
}

@media (--viewport-lg-min) {
    .actie-slider-section {
        position: relative;
        z-index: 4;
        margin-top: 0;
        overflow: hidden;
    
        & .actie-carousel {
            position: relative;
            overflow: hidden;
            width: calc(100vw - ((100vw - var(--site-width) + var(--gutter-width)) / 2));
            padding-left: 5%;
    
            & .carousel-cell {
                width: calc((var(--site-width) - var(--gutter-width)) / 2);
                padding-right: 20px;
    
                &.hidden {
    
                    & .image {
                        position: relative;
                        overflow: hidden;

                        & img {
                            opacity: .4;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            z-index: 4;
                            opacity: 0;
                            top: 0;
                            left: 0;
                            background-color: var(--color-blue);
                            height: 100%;
                            width: 100%;
                            transition: .3s;
                            pointer-events: none;
                            user-select: none;
                        }

                        &:hover {
                            cursor: none;
                        }
    
                        &:hover::before {
                            opacity: .8;
                        }

                        & .actionCursor {
                            position: absolute;
                            z-index: 99;
                            width: 35px;
                            height: 22px;
                            background: white;
                            /* border-radius: 50%; */
                            /* mix-blend-mode: difference; */
                            pointer-events: none;
                            transform: scale(0);
                            transition: transform .15s ease-in-out;
                        
                            background: url(/dist/images/next-icon.svg);
                            background-size: 35px 22px;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                        }
                    }
    
                    & .content {
                        opacity: 0;
                    }
                }
    
                & .image {
                    background-color: #000;
    
                    & img {
                        /* opacity: 0.2; */
                        transition: .6s;
                    }
                }
    
                & .content {
                    width: calc(200% + 20px);
                    transition: .6s ease-in-out;
    
                    & .content-wrap {
                        display: flex;
                        justify-content: space-between;
    
                        & .title {
                            width: calc(50% - 10px);
    
                            & h2 {
                                font-size: 2.85em;
                                margin: 36px 0 54px;
                            }
                        }
    
                        & .text {
                            width: calc(50% - 10px);
                            
                            & .actie-p {
                                display: block;
                                margin-top: 44px;
                            }

                            & .actie-button {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        & .actie-button-group {
            padding-left: 5%;
        }
    }
}