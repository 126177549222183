.header-logo {
    position: fixed;
    z-index: 209;
    top: 30px;
    left: 30px;
    /* margin-left: 5%; */
    transition: .3s;

    & img {
        width: 180px;
        transition: .3s;
    }
}

.header-quick-nav {
    display: none;
    position: fixed;
    z-index: 202;
    top: 22px;
    right: 70px;
    /* margin-right: 5%; */
    transition: .3s;

    & .wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & .button {
            color: #fff;

            &.button-hero {
                background-color: #fff;
                color: var(--color-blue);
                border: 1px solid #fff;

                &:hover {
                    background-color: var(--color-blue);
                    color: #fff;
                    border: 1px solid var(--color-blue);
                }
            }

            &.alt {
                background-color: var(--color-pink);
                color: var(--color-white);
                border: 1px solid var(--color-pink);

                &:hover {
                    background-color: var(--color-blue);
                    color: #fff;
                    border: 1px solid var(--color-blue);
                }
            }
        }

        & ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            & a {
                color: inherit;
                text-decoration: none;
            }
        }

        & ul.hqn-menu {
            color: #fff;
            font-weight: 600;

            & li.hqn-menu-item {
                position: relative;
                padding: 12px 16px;
                text-transform: uppercase;
                margin-right: 20px;

                &:hover ul.hqn-submenu {
                    display: block;
                }

                & .hqn-locaties {
                    display: flex;
                    align-items: center;

                    & .hqn-locaties-text {
                        margin-right: 8px;
                    }

                    & svg {
                        width: 14px;
                    }
                }

                & ul.hqn-submenu {
                    display: none;
                    text-transform: none;
                    position: absolute;
                    top: 46px;
                    right: 0;
                    min-width: 250px;
                    background-color: #fff;
                    padding: 12px;
                    color: var(--color-blue);

                    & a {
                        display: block;

                        &:hover {
                            color: var(--color-pink);
                        }
                    }
                }
            }
        }
    }
}

.header-hamburger {
    position: fixed;
    top: 34px;
    right: 30px;
    /* margin-right: 5%; */
    z-index: 210;
    cursor: pointer;
    transition: .3s;

    &.menu-is-active {

        & .hamburger {

            & span {

                &:first-child {
                    top: 10px;
                    transform: scaleX(0);
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    top: 10px;
                    transform: scaleX(0);
                }

                &:nth-child(4) {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    & .hamburger {
        width: 24px;
        height: 24px;
        position: relative;

        & span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: #fff;
            opacity: 1;
            left: 0;
            transition: .25s ease-in-out;

            &:first-child {
                top: 2px;
            }

            &:nth-child(2) {
                top: 10px;
            }

            &:nth-child(3) {
                top: 18px;
            }

            &:nth-child(4) {
                top: 10px;
            }
        }
    }
}

.header-background-bar {
    position: fixed;
    height: 100px;
    background-color: var(--color-blue);
    width: 100%;
    left: 0;
    top: 0;
    z-index: 199;
    opacity: 0;
    transition: .3s;
    pointer-events: none;
}

.navScroll {

    .header-logo {
        top: 20px;

        & img {
            width: 160px;
        }
    }

    .header-quick-nav {
        top: 11px;
    }

    .header-hamburger {
        top: 26px;
    }

    & .header-background-bar {
        height: 78px;
        pointer-events: auto;
        opacity: 1;
    }
}

@media (--viewport-sm-min) {
    .header-logo {
        margin-left: 5%;
    }

    .header-quick-nav {
        display: block;
        margin-right: 5%;
    }

    .header-hamburger {
        margin-right: 5%;
    }
}

@media (--viewport-md-min) {

    .header-logo {
        top: 36px;

        & img {
            width: 250px;
        }
    }

    .header-quick-nav {
        top: 36px;
    }

    .header-hamburger {
        top: 51px;
    }

    .header-background-bar {
        height: 128px;
    }


    .navScroll {

        .header-logo {
            top: 24px;

            & img {
                width: 200px;
            }
        }

        .header-quick-nav {
            top: 22px;
        }

        .header-hamburger {
            top: 36px;
        }

        & .header-background-bar {
            height: 98px;
            pointer-events: auto;
            opacity: 1;
        }
    }
}

@media (--viewport-lg-min) {
    .header-logo {
        top: 36px;
        left: calc((100% - var(--site-width)) / 2 + var(--half-gutter-width));
        margin-left: calc(var(--site-width) / 20);

        & img {
            width: 250px;
        }
    }

    .header-quick-nav {
        top: 36px;
        right: calc((100% - var(--site-width)) / 2 + var(--half-gutter-width) + 50px);
        margin-right: calc(var(--site-width) / 20);
    }

    .header-hamburger {
        position: fixed;
        top: 51px;
        right: calc((100% - var(--site-width)) / 2 + var(--half-gutter-width));
        margin-right: calc(var(--site-width) / 20);
    }
}