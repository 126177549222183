.image-text-section {
    overflow: hidden;

    & .image-text {
        display: flex;
        flex-direction: column;
    
        &.reverse {
            flex-direction: column;
        }
    
        & .text {
            align-self: center;
            width: 100%;
            padding: 0px;
            margin-bottom: 2em;
    
            & .small-top-heading {
                position: relative;
                display: block;
                font-size: 1.2em;
                font-family: var(--heading-font-family);
    
                & .sth-text {
                    position: relative;
                    z-index: 2;
                    display: inline-block;
                    padding-right: 1em;
                    background-color: #fff;
                }
    
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 140%;
                    height: 1px;
                    background-color: var(--color-blue);
                    top: 50%;
                    left: 0;
                }
            }
    
            & h2 {
                font-size: 1.9em;
                margin: 1.5em 0;
            }
    
            & .it-button {
                margin: 1.5em 0 0;
            }
        }
        
        & .image {
            width: 100%;
        }
    }
}

@media (--viewport-ms-max) {
    .image-text-section .image-text.mobile-image-first {
        flex-direction: column-reverse;

        & .text {
            margin-bottom: 0;
        }

        & .image {
            margin-bottom: 2em;
        }
    }
}

@media (--viewport-sm-min) {
    .image-text-section {

        & .image-text {
            flex-direction: row;
        
            &.reverse {
                flex-direction: row-reverse;

                & .text .small-top-heading:after {
                    width: 100%;
                }
            }
        
            & .text {
                width: 50%;
                padding: 2em 5%;
                margin-bottom: 0;

                & .small-top-heading {
                    /* font-size: 1.4em; */
                }

                & h2 {
                    margin: 1.2em 0;
                }

                & .it-button {
                    margin: 2em 0 0;
                }
            }
            
            & .image {
                width: 50%;

                & figure {
                    height: 100%;

                    & img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .image-text-section {

        & .image-text {
        
            & .text {
                /* padding: 2em 5%; */

                & .small-top-heading {
                    font-size: 1.3em;
                }

                & h2 {
                    font-size: 2.25em;
                    margin: 1.6em 0;
                }

                & .it-button {
                    margin: 2.5em 0 0;
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .image-text-section {

        & .image-text {
        
            & .text {

                & .small-top-heading {
                    font-size: 1.4em;
                }

                & h2 {
                    font-size: 2.85em;
                    margin: 1.9em 0;
                }

                & .it-button {
                    margin: 3em 0 0;
                }
            }
        }
    }
}