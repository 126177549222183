.vacature-overview-section {

    .wrap {

        .vacature-tile {
            background-color: var(--color-creme);
            border-radius: 3px;
            margin-bottom: 22px;

            a {
                color: inherit;
                text-decoration: none;
                    
                &:hover .vt-link {
                    color: var(--color-pink);
                }
            }
        
            .vt-wrap {
                padding: 18px;

                h3 {
                    font-size: 1.5em;
                    margin-top: 0;
                }
        
                .vt-icons {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-bottom: 14px;
    
                    .vt-icon {
                        margin-right: 30px;
                        font-weight: 600;
    
                        i {
                            color: var(--color-pink);
                        }
                    }
                }

                .vt-link {
                    font-weight: 600;
                    text-transform: uppercase;
                    transition: .2s;
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .vacature-overview-section {

        .wrap {
            padding: 0 5%;

            .vacature-tile {
        
                .vt-wrap {
                    padding: 24px;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .vacature-overview-section {

        .wrap {

        }
    }
}