.trainer-text-section {
    overflow: hidden;

    & .trainer-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        & .text {
            align-self: center;
            width: 100%;
            padding: 0px;
            /* margin-bottom: 2em; */
    
            & .small-top-heading {
                position: relative;
                display: block;
                font-size: 1.2em;
                font-family: var(--heading-font-family);
    
                & .sth-text {
                    position: relative;
                    z-index: 2;
                    display: inline-block;
                    padding-right: 1em;
                    background-color: #fff;
                }
    
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 140%;
                    height: 1px;
                    background-color: var(--color-blue);
                    top: 50%;
                    left: 0;
                }
            }
    
            & h2 {
                font-size: 1.9em;
                margin: 1.5em 0;
            }
    
            & .it-button {
                margin: 1.5em 0 0;
            }
        }
        
        & .trainer {
            width: 100%;
            margin-bottom: 2em;

            & .trainer-wrap {
                position: relative;
                border-radius: 3px;
                overflow: hidden;

                & img {
                    position: relative;
                    z-index: 3;
                    display: block;
                    width: 100%;
                }
    
                &::after {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 80%;
                    bottom: 0;
                    left: 0;
                    background-color: var(--color-creme);
                    transition: .3s;
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .trainer-text-section {

        & .trainer-text {
            flex-direction: row;
            padding-left: 5%;
        
            & .text {
                width: 50%;
                padding: 2em 5%;
                margin-bottom: 0;

                & .small-top-heading {
                    /* font-size: 1.4em; */
                }

                & h2 {
                    margin: 1.2em 0;
                }

                & .it-button {
                    margin: 2em 0 0;
                }
            }
            
            & .trainer {
                width: 50%;
                margin: 0;
            }
        }
    }
}

@media (--viewport-md-min) {
    .trainer-text-section {

        & .trainer-text {
        
            & .text {
                /* padding: 2em 5%; */

                & .small-top-heading {
                    font-size: 1.3em;
    
                    &:after {
                        width: 100%;
                    }
                }

                & h2 {
                    font-size: 2.25em;
                    margin: 1.6em 0;
                }

                & .it-button {
                    margin: 2.5em 0 0;
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .trainer-text-section {

        & .trainer-text {
        
            & .text {

                & .small-top-heading {
                    font-size: 1.4em;
                }

                & h2 {
                    font-size: 2.85em;
                    margin: 1.9em 0;
                }

                & .it-button {
                    margin: 3em 0 0;
                }
            }
        }
    }
}