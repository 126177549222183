.image-slider-club-select-section {

    & .club-select-center {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    & .iscs-carousel {

        & img {
            width: auto;
            height: 200px;
            display: block;
            padding: 0 6px;
        }
    }

    & .switch-locations {
        display: none;

        & img {
            display: none;
        }
    }
}

@media (--viewport-sm-min) {
    .image-slider-club-select-section {
    
        & .iscs-carousel {
    
            & img {
                height: 340px;
                padding: 0 8px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .image-slider-club-select-section {

        & .club-select-center {
            margin-bottom: 40px;
        }
    
        & .iscs-carousel {
    
            & img {
                height: 420px;
                padding: 0 10px;
            }
        }
    }
}