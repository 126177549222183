.tarieven-overzicht-section {
    background-color: var(--color-creme);
    margin: 0;
    padding: 40px 0;
    overflow: hidden;

    & .wrap {

        & .header {

            & h2 {
                position: relative;
                display: block;
                font-size: 1.2em;
                font-family: var(--heading-font-family);
    
                & .tos-text {
                    position: relative;
                    z-index: 2;
                    display: inline-block;
                    padding-right: 1em;
                    background-color: var(--color-creme);
                }
    
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 140%;
                    height: 1px;
                    background-color: var(--color-blue);
                    top: 50%;
                    left: 0;
                }
            }
        }

        & .tarieven {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
            /* margin: 0 -6px; */

            & .tarieven-tile {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;

                & .top {

                    & h3 {
                        font-size: 2em;
                        margin: 1em 0 .7em;
                    }
    
                    & ul {
                        list-style-type: none;
                        margin: 0 0 24px;
                        padding: 0;
    
                        & li {
                            position: relative;
                            padding-left: 28px;
                            margin-bottom: 4px;
    
                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 28px;
                                height: 100%;
                                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23E5007B' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                                background-size: 24px 1em;
                                background-position: 0px 4px;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }

                & .bottom {
                    margin-top: auto;

                    & .price-wrap {
    
                        & .price {
                            display: block;
                            font-size: 2em;
                            font-family: var(--heading-font-family);
                        }
    
                        & .price-month {
                            display: block;
                            font-family: var(--heading-font-family);
                            font-size: 1.3em;
                        }
    
                        & .abbo {
                            display: block;
                            margin-top: 1rem;
                            font-size: .8em;
                        }
                    }
    
                    & .tos-button {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .tarieven-overzicht-section {
        padding: 60px 0;
    
        & .wrap {
            padding: 0 5%;

            & .tarieven {
                flex-direction: row;
    
                & .tarieven-tile {
                    width: 31%;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .tarieven-overzicht-section {
        padding: 80px 0;
    
        & .wrap {
            
            & .header {
            
                & h2 {
                    font-size: 1.3em;
    
                    &:after {
                        width: 100%;
                    }
                }
            }

            & .tarieven {
                flex-direction: row;
    
                & .tarieven-tile {
                    width: 33%;
                    margin-bottom: 0;
                    padding-right: 30px;
    
                    & .top {
    
                        & h3 {
                            font-size: 2.25em;
                            margin: 1em 0;
                        }

                        & ul {
                            margin: 0 0 40px;
                        }
                    }

                    & .bottom {
    
                        & .price-wrap {
        
                            & .price {
                                font-size: 2.25em;
                            }
        
                            & .price-month {
                                font-size: 1.3em;
                            }
        
                            & .abbo {
                                font-size: .9em;
                            }
                        }
        
                        & .tos-button {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .tarieven-overzicht-section {
    
        & .wrap {
            
            & .header {
            
                & h2 {
                    font-size: 1.4em;
                }
            }
        }
    }
}


