.club-nav-section {
    display: none;
    margin: 0;
    padding: 20px 0;
    background-color: var(--color-creme);
    overflow: hidden;
    position: relative;

    #clubNav {
        transform: translateY(-88px);
    }

    & .club-nav-carousel {
        font-family: var(--heading-font-family);
        font-size: 16px;

        & .flickity-viewport {
            overflow: visible;
        }

        &:after {
            content: 'flickity';
            display: none; /* hide :after */
        }

        & .carousel-cell {
            /* width: 100%; */
            display: block;

            &:last-child .link-wrap::after {
                content: none;
            }

            & .link-wrap {
                display: flex;
                flex-wrap: nowrap;
                white-space: nowrap;
                padding: 20px 0;

                &::after {
                    display: block;
                    content: '|';
                    padding: 0 12px;
                }

                & a {
                    display: block;
                    color: inherit;
                    text-decoration: none;
                    position: relative;

                    &:hover {
                        color: var(--color-pink);
                    }
                }
            }
        }
    }

    /* .swipe-gesture {
        position: absolute;
        bottom: 0;
        right: 20px;
        animation: animateGesture 2s infinite;
        
        img {
            display: block;
            width: 32px;
        }
    } */
}

@media (--viewport-md-min) {
    .club-nav-section {
        display: block;
        padding: 0;
        margin: 50px 0;
        background-color: transparent;
        overflow: visible;

        #clubNav {
            transform: translateY(-138px);
        }
    
        & .club-nav-carousel {
            display: flex;
            justify-content: center;
            font-size: 18px;

            &:after {
                content: '';
            }

            & .carousel-cell {

                & .link-wrap {

                    & a {

                        &:hover {
                            color: inherit;

                            &::before {
                                width: 20px;
                            }
                        }
            
                        &::before {
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 1px;
                            background-color: var(--color-blue);
                            bottom: -12px;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: .3s;
                        }
                    }
                }
            }
        }
    }
}

@keyframes animateGesture {
	
	0%{
		opacity: 0;
		transform: translateX(-15px);
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0;
		transform: translateX(15px);
	}
}