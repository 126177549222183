.faq-accordion-section {

    .video-wrap {
        width: 100%;
        padding-bottom: 16px;
    }

    .wrap {

        .faq-accordion {
            margin-bottom: 12px;
            border-radius: 3px;
            overflow: hidden;

            .accordion {
                font-family: var(--heading-font-family);
                background-color: var(--color-creme);
                color: var(--color-blue);
                font-weight: 400;
                font-size: 16px;

                &:after {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='107' height='57' viewBox='0 0 107 57' fill='none'%3E%3Cpath d='M106.148 5.24985L101.364 0.793386C100.229 -0.264462 98.3924 -0.264462 97.2568 0.793386L53.5 41.4643L9.7432 0.793386C8.6076 -0.264462 6.77131 -0.264462 5.63571 0.793386L0.851699 5.24985C-0.2839 6.3077 -0.2839 8.01826 0.851699 9.07611L51.4463 56.2066C52.5819 57.2645 54.4181 57.2645 55.5537 56.2066L106.148 9.07611C107.284 8.01826 107.284 6.3077 106.148 5.24985V5.24985Z' fill='%23021A2B'/%3E%3C/svg%3E");
                    width: 20px;
                    height: 1em;
                    background-size: 16px 9px;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: .2s;
                }

                &:hover:after {
                    color: var(--color-primary-offset);
                }

                &.active:after {
                    content: "";
                    transform: scaleY(-1);
                }
            }

            .panel {
                background-color: var(--color-creme);
            }
        }
    }
}

@media (--viewport-sm-min) {
    .faq-accordion-section {

        .wrap {
            padding: 0 5%;
        }
    }
}

@media (--viewport-md-min) {
    .faq-accordion-section {

        .wrap {

        }
    }
}