.vacature-detail-section {

    .vds-wrap {
        max-width: 700px;
        margin: 0 auto;

        .vds-content {

            .vds-intro {
                margin-bottom: 36px;

                .vds-back {

                    a {
                        color: inherit;
                        text-decoration: none;

                        &:hover {
                            color: var(--color-pink);

                            &:before {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='58' viewBox='0 0 100 58' fill='none'%3E%3Cpath d='M31.3571 0.460414L34.0576 3.05361C34.6968 3.66736 34.6968 4.66252 34.0576 5.27641L12.8987 25.5946H98.3633C99.2672 25.5946 100 26.2983 100 27.1663V30.8337C100 31.7016 99.2672 32.4054 98.3633 32.4054H12.8987L34.0576 52.7237C34.6968 53.3374 34.6968 54.3326 34.0576 54.9465L31.3571 57.5397C30.718 58.1534 29.6817 58.1534 29.0424 57.5397L0.479361 30.1114C-0.159785 29.4977 -0.159785 28.5025 0.479361 27.8887L29.0425 0.460414C29.6817 -0.153471 30.718 -0.153471 31.3571 0.460414Z' fill='%23e5007b'/%3E%3C/svg%3E");
                            }
                        }

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 20px;
                            height: 12px;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='58' viewBox='0 0 100 58' fill='none'%3E%3Cpath d='M31.3571 0.460414L34.0576 3.05361C34.6968 3.66736 34.6968 4.66252 34.0576 5.27641L12.8987 25.5946H98.3633C99.2672 25.5946 100 26.2983 100 27.1663V30.8337C100 31.7016 99.2672 32.4054 98.3633 32.4054H12.8987L34.0576 52.7237C34.6968 53.3374 34.6968 54.3326 34.0576 54.9465L31.3571 57.5397C30.718 58.1534 29.6817 58.1534 29.0424 57.5397L0.479361 30.1114C-0.159785 29.4977 -0.159785 28.5025 0.479361 27.8887L29.0425 0.460414C29.6817 -0.153471 30.718 -0.153471 31.3571 0.460414Z' fill='%23021A2B'/%3E%3C/svg%3E");
                            background-size: 20px 12px;
                            background-position: center;
                            background-repeat: no-repeat;
                            margin-right: 12px;
                        }
                    }
                }

                h2 {
                    font-size: 32px;
                    word-break: break-all;
                    margin: 16px 0;
                }

                .vds-icons {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-bottom: 14px;

                    .vds-icon {
                        margin-right: 30px;
                        font-weight: 600;

                        i {
                            color: var(--color-pink);
                        }
                    }
                }

                .intro {
                    /* font-weight: 600; */
                    font-size: 15px;
                }
            }

            .vds-text {
                margin-bottom: 48px;

                p,
                ul {
                    margin-bottom: 1.6em;
                }
            }
        }

        .vds-footer {
            padding: 22px;
            border-radius: 3px;
            background-color: var(--color-creme);

            h2 {
                margin-top: 14px;
            }
        }
    }
}

.vacature-detail-slider-section {

    .vdss-carousel {

        img {
            width: auto;
            height: 200px;
            display: block;
            padding: 0 6px;
        }
    }
}

@media (--viewport-sm-min) {
    .vacature-detail-slider-section {

        .vdss-carousel {

            img {
                height: 340px;
                padding: 0 8px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .vacature-detail-section {

        .vds-wrap {

            .vds-content {

                .vds-intro {

                    h2 {
                        font-size: 46px;
                    }
                }
            }
        }
    }

    .vacature-detail-slider-section {

        .vdss-carousel {

            img {
                height: 420px;
                padding: 0 10px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .vacature-detail-slider-section {

        .vdss-carousel {

            img {
                height: 520px;
            }
        }
    }
}